import React, { useState } from "react";
import { Button } from "react-bootstrap";

const EditUserForm = ({ user, onSave, onCancel }) => {
  const [editedUser, setEditedUser] = useState({ ...user });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(editedUser);
  };

  return (
    <div className="edit-user-form">
      <input
        type="text"
        name="UserName"
        value={editedUser.UserName}
        onChange={handleChange}
        placeholder="Name"
        className="form-control mb-2"
      />
      <input
        type="email"
        name="UserEmail"
        value={editedUser.UserEmail}
        onChange={handleChange}
        placeholder="Email"
        className="form-control mb-2"
      />
      <select
        name="Role"
        value={editedUser.Role}
        onChange={handleChange}
        className="form-control mb-2"
      >
        <option value="Admin">Admin</option>
        <option value="User">User</option>
      </select>
      <div className="btn-group">
        <Button variant="success" onClick={handleSave} className="me-2">
          Save
        </Button>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditUserForm;
