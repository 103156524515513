import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OperationModel.css"; // Import CSS file
import {
  faComment,
  faClipboardCheck,
  faTools,
  faLifeRing, // Revert to the old icon
} from "@fortawesome/free-solid-svg-icons";

const OperationModel = () => {
  return (
    <div className="operation-model-section" id="operation-model">
      <div className="container">
        <h2 className="section-title">Our Operation Model</h2>
        <div className="operation-model-header">
          <p>
            At GeniusNexa, we follow a client-centric operation model to ensure
            we deliver solutions that are precisely tailored to our clients'
            unique needs. Our process involves the following key steps:
          </p>
        </div>
        <ul className="operation-model">
          <li>
            <FontAwesomeIcon icon={faComment} className="gear-icon" />{" "}
            {/* Revert to the old icon */}
            <div className="operation-content">
              <strong>Consultation:</strong>{" "}
              <span className="highlight-text">
                Understanding client requirements and goals through detailed
                discussions and analysis.
              </span>{" "}
              We engage closely with our clients to grasp their vision and
              objectives, ensuring alignment from the outset.
            </div>
          </li>
          <li>
            <FontAwesomeIcon icon={faClipboardCheck} className="gear-icon" />{" "}
            {/* Revert to the old icon */}
            <div className="operation-content">
              <strong>Planning:</strong>{" "}
              <span className="highlight-text">
                Developing a strategic plan that outlines the steps and
                technologies needed to achieve the desired outcomes.
              </span>{" "}
              Our team creates a comprehensive roadmap that encompasses
              timelines, milestones, and resource allocation.
            </div>
          </li>
          <li>
            <FontAwesomeIcon icon={faTools} className="gear-icon" />{" "}
            {/* Revert to the old icon */}
            <div className="operation-content">
              <strong>Execution:</strong>{" "}
              <span className="highlight-text">
                Implementing the plan using cutting-edge technologies and best
                practices.
              </span>{" "}
              We leverage our expertise in software development and project
              management to bring your vision to life efficiently and
              effectively.
            </div>
          </li>
          <li>
            <FontAwesomeIcon icon={faLifeRing} className="gear-icon" />{" "}
            {/* Revert to the old icon */}
            <div className="operation-content">
              <strong>Support:</strong>{" "}
              <span className="highlight-text">
                Providing ongoing support and maintenance to ensure the
                continued success of the solution.
              </span>{" "}
              Our commitment extends beyond project delivery, as we offer
              continuous support to optimize performance and address evolving
              needs.
            </div>
          </li>
        </ul>
        <p>
          At GeniusNexa, we are committed to delivering exceptional service
          tailored to your specific business requirements. Whether you're
          launching a new application, enhancing existing software, or
          integrating complex systems, our agile approach ensures rapid
          deployment without compromising quality. We offer flexible engagement
          models and competitive pricing to meet your budgetary constraints,
          making us the ideal partner for your software development needs.
        </p>
        <p>
          <span className="highlight-text">
            Contact us today to learn how GeniusNexa can empower your business
            with innovative solutions and unparalleled support.
          </span>
        </p>
      </div>
    </div>
  );
};

export default OperationModel;
