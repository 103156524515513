import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
//import { FaFacebook } from "react-icons/fa";
import "./Login.css";
import UserContext from "../../contexts/UserContext";
import apiService from "../../ApiService"; // Adjust the import path as necessary
import GoogleLoginButton from "../../components/GoogleLoginButton";

const Login = () => {
  const [useremail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { login, isAuthenticated } = useContext(UserContext);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await apiService.login(useremail, password, "login"); // Call the login method from apiService

      if (result.success) {
        login(result.user);
        const { role } = result.user;
        if (role === "admin") {
          history.push("/admin");
        } else {
          history.push("/");
        }
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid credentials");
      }
    } catch (error) {
      setErrorMessage("Error logging in. Please try again.");
      console.error("Error logging in:", error);
    }
  };

  // const handleSocialLogin = async (provider) => {
  //   try {
  //     const result = await apiService.socialLogin(provider);
  //     if (result.success) {
  //       login(result.user);
  //       const { role } = result.user;
  //       if (role === "admin") {
  //         history.push("/admin");
  //       } else {
  //         history.push("/");
  //       }
  //       setErrorMessage("");
  //     } else {
  //       setErrorMessage("Social login failed");
  //     }
  //   } catch (error) {
  //     setErrorMessage("Error with social login. Please try again.");
  //     console.error(`Error with ${provider} login:`, error);
  //   }
  // };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    } else {
      setLoading(false);
    }
  }, [isAuthenticated, history]);

  if (loading) {
    return <div className="loading-message">Checking login status...</div>;
  }

  return (
    <div className="container">
      <div className="login-container">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label>Email:</label>
            <input
              name="useremail"
              type="email"
              value={useremail}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submitbtn">
            Login
          </button>
        </form>
        <hr />
        <div className="social-login">
          {/* <button
            onClick={() => handleSocialLogin("facebook")}
            className="social-button facebook-button"
          >
            <FaFacebook /> Login with Facebook
          </button> */}
          <GoogleLoginButton />
        </div>
        <hr />
        <p>If you are a new user, please register below.</p>
        <button
          onClick={() => history.push("/register")}
          className="social-button register-button"
        >
          Register
        </button>
      </div>
    </div>
  );
};

export default Login;
