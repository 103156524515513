import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";
import SEO from "../common/SEO";
import { baseUrl } from "../config";
import "./Tools.css";
import { trackEvent } from "../components/trackingUtils";
const tools = [
  {
    id: "seonavigator",
    name: "SEO Navigator",
    image: "/images/seonavigator.png",
    description:
      "SEO Navigator is a comprehensive tool designed to help businesses and individuals optimize their websites for search engines. It provides valuable insights into keyword performance, backlink analysis, on-page SEO, and technical site audits. With user-friendly features, SEO Navigator simplifies the process of improving search engine rankings, driving organic traffic, and enhancing overall website visibility. Whether you're a seasoned SEO professional or just starting, this tool equips you with the data and strategies needed to navigate the complexities of SEO and achieve better online success.",
  },
  {
    id: "imageconverter",
    name: "Image Converter",
    image: "/images/ImageConverter.png",
    description:
      "The Image converter is a versatile React-based tool designed to streamline the conversion of images into formats optimized for various social media platforms, including Twitter, Facebook, WhatsApp, and LinkedIn. It offers a seamless user experience with real-time feedback during image processing, ensuring that users can quickly and efficiently prepare images for multiple platforms without the need for separate tools. Additionally, the component includes robust error handling and integrates SEO optimization to enhance discoverability, making it an essential resource for content creators and social media managers.",
  },
];

const Tools = () => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    trackEvent("PageLoad", "", "Tools");
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 second delay
    return () => clearTimeout(timer);
  }, []);

  const handleCardClick = (id) => {
    history.push(`/tools/${id}`);
  };

  if (loading) {
    return (
      <Container>
        <div className="spinner-container">
          <Spinner animation="border" />
        </div>
      </Container>
    );
  }

  return (
    <>
      <SEO
        title="Explore Innovative Tools to Enhance Your Online Presence | GeniusNexa"
        description={`Discover a range of innovative tools designed to optimize and enhance your online presence. From in-depth analyses to powerful optimizations, find the perfect tool to boost performance and achieve your digital goals.`}
        keywords="online optimization tools, digital marketing tools, performance enhancement, innovative tools, online presence improvement, GeniusNexa"
        url={baseUrl + "/tools"}
        image={baseUrl + "/images/tools.png"} // Update this if you have a generic image for tools
      />

      <Container>
        <div className="tools-list">
          <h2>Our Tools</h2>
          <div className="tools-grid">
            {tools.map((tool) => (
              <div
                key={tool.id}
                className="tool-card"
                onClick={() => handleCardClick(tool.id)}
              >
                <img src={tool.image} alt={tool.name} className="tool-image" />
                <h3>{tool.name}</h3>
                <p>{tool.description}</p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Tools;
