import React from "react";
import "./AboutUs.css";

const AboutUsSection = () => {
  return (
    <div className="AboutUs-section" id="about-us">
      <div className="container">
        <h2 className="section-title ">About GeniusNexa</h2>
        <div className="section-content">
          <p>
            Welcome to the world of GeniusNexa, where futuristic technologies
            become mind-blowing realities. Step into the future as we unleash a
            symphony of cutting-edge advancements that redefine the boundaries
            of innovation. At GeniusNexa, our mission is to push the limits of
            human potential and revolutionize industries through the power of
            technology. From artificial intelligence to blockchain, our
            groundbreaking solutions are designed to enhance efficiency, improve
            processes, and transform the way we live and work. With a team of
            brilliant minds and a relentless passion for innovation, we're at
            the forefront of the technological revolution. Our commitment to
            research and development has led to breakthroughs in fields such as
            automation, robotics, and Internet of Things (IoT), creating a
            revolution that disrupts the status quo. Join us on a journey to
            explore the incredible advancements and mind-bending possibilities
            that GeniusNexa has to offer. Discover how our cutting-edge
            technologies are shaping the future and unlocking opportunities that
            were once unimaginable. The future is here, and it's powered by
            GeniusNexa. Get ready to be blown away. Remember, the best is yet to
            come. Welcome to the world of GeniusNexa.
          </p>
          <div className="vision-mission twelve">
            <h3>Our Vision</h3>
            <p>
              To lead the industry as the foremost provider of innovative
              software solutions that empower businesses globally.
            </p>
            <h3>Our Mission</h3>
            <p>
              We are committed to delivering exceptional value by harnessing
              advanced technology and a dedicated team focused on surpassing
              client expectations.
            </p>
            <h3>Our Values</h3>
            <ul>
              <li>
                We prioritize client success above all else, driving every
                decision.
              </li>
              <li>
                We embrace innovation, constantly striving for breakthrough
                solutions.
              </li>
              <li>
                We foster a culture of collaboration, integrity, and mutual
                respect.
              </li>
              <li>
                We uphold excellence in everything we do, ensuring unmatched
                quality.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
