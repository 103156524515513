import React, { useState } from "react";
import "./ContactUs.css"; // Import your CSS file
import apiService from "../../ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const ContactUsSection = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    recipient: "support@geniusnexa.com",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await apiService.sendEmail({
        name: formData.name,
        email: formData.email,
        message: formData.message,
        recipient: formData.recipient,
      });

      setStatusMessage(
        result.status === "success"
          ? `Thank you, ${formData.name}, for reaching out. We will get back to you within 2 hours.`
          : "An error occurred while sending the message. Please try again."
      );

      if (result.status === "success") {
        // Reset form fields
        setFormData({
          name: "",
          email: "",
          message: "",
          recipient: "support@geniusnexa.com",
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatusMessage(
        "An error occurred while sending the message. Please try again."
      );
    }
  };

  return (
    <div className="contact-us-section" id="contact-us">
      <div className="container">
        <h2 className="section-title">Contact Us</h2>
        <div className="contact-content">
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  autoComplete="name"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="5"
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn-submit">
                Send Message
              </button>
            </form>
            <p
              className={
                statusMessage.includes("Thank")
                  ? "status-message success"
                  : "status-message failure"
              }
            >
              {statusMessage}
            </p>
          </div>
          <div className="contact-info">
            <div className="social-links">
              <a
                href="https://www.facebook.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/company/103303143/admin/feed/posts/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/yourprofile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </div>
          </div>
          <div className="contact-map"></div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
