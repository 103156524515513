// Services.js

import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faCode,
  faFilePdf,
  faDatabase,
  faGlobe,
  faTools,
} from "@fortawesome/free-solid-svg-icons";
import "./Services.css";

const Services = ({ services }) => {
  const getIcon = (iconName) => {
    switch (iconName) {
      case "faCloud":
        return faCloud;
      case "faCode":
        return faCode;
      case "faFilePdf":
        return faFilePdf;
      case "faTools":
        return faTools;
      case "faDatabase":
        return faDatabase;
      case "faGlobe":
        return faGlobe;
      default:
        return null;
    }
  };

  return (
    <div className="container" id="services">
      <h2 className="section-title">Our Services</h2>
      <div className="services-container">
        {services &&
          services.map((service, index) => {
            const icon = getIcon(service.icon);
            if (!icon) return null; // Handle cases where icon is not found

            return (
              <Link
                to={`services/${service.url}`}
                key={index}
                className="service-link"
              >
                <div className="service-card">
                  <div className="service-card-inner">
                    <div className="service-card-front">
                      <FontAwesomeIcon className="icon" icon={icon} size="3x" />
                      <h3>{service.title}</h3>
                    </div>
                    <div className="service-card-back">
                      <p>{service.description}</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
      </div>
    </div>
  );
};

export default Services;
