import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faFileAlt,
  faUsers,
  faCog,
  faChartBar,
  faTools,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import UserContext from "../../contexts/UserContext";
import AdminDashboard from "./AdminDashboard";
import AdminPosts from "./Posts";
import ReviewBlog from "./ReviewBlogs";
import Users from "./Users";
import AdminSettings from "./AdminSettings";
import AdminReports from "./AdminReports";
import AdminTools from "./AdminTools";
import SubItem1 from "./SubItem1";
import SubItem2 from "./SubItem2";
import "./AdminLayout.css";

const AdminLayout = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  const [open, setOpen] = useState({});
  const [activeComponent, setActiveComponent] = useState(null);

  // Function to determine if the user is an admin
  const isAdminUser = () => {
    return user && user.role === "admin";
  };

  // Effect to set active component based on the current path
  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/admin/dashboard":
        setActiveComponent("dashboard");
        break;
      case "/admin/posts":
        setActiveComponent("posts");
        break;
      case "/admin/reviewblog":
        setActiveComponent("reviewblog");
        break;
      case "/admin/users":
        setActiveComponent("users");
        break;
      case "/admin/settings":
        setActiveComponent("settings");
        break;
      case "/admin/reports":
        setActiveComponent("reports");
        break;
      case "/admin/tools":
        setActiveComponent("tools");
        break;
      case "/admin/subitem1":
        setActiveComponent("subitem1");
        break;
      case "/admin/subitem2":
        setActiveComponent("subitem2");
        break;
      default:
        setActiveComponent(null);
    }
  }, [location.pathname]);

  // Function to render the active component based on activeComponent state
  const renderActiveComponent = () => {
    switch (activeComponent) {
      case "dashboard":
        return <AdminDashboard />;
      case "posts":
        return <AdminPosts user={user} />;
      case "reviewblog":
        return <ReviewBlog user={user} />;
      case "users":
        return <Users />;
      case "settings":
        return <AdminSettings />;
      case "reports":
        return <AdminReports />;
      case "tools":
        return <AdminTools />;
      case "subitem1":
        return <SubItem1 />;
      case "subitem2":
        return <SubItem2 />;
      default:
        return <div>Select an option from the menu</div>;
    }
  };

  // Handle toggle for submenus
  const handleToggle = (menu) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [menu]: !prevOpen[menu],
    }));
  };

  return (
    <div className="admin-layout">
      <aside className="admin-sidebar">
        <nav className="admin-nav">
          {isAdminUser() ? (
            <ul className="list-unstyled components">
              <li className={activeComponent === "dashboard" ? "active" : ""}>
                <Link
                  to="/admin/dashboard"
                  onClick={() => setActiveComponent("dashboard")}
                  data-description="Dashboard"
                >
                  <FontAwesomeIcon icon={faTachometerAlt} />
                  &ensp;
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className={activeComponent === "posts" ? "active" : ""}>
                <Link
                  to="/admin/posts"
                  onClick={() => setActiveComponent("posts")}
                  data-description="Posts"
                >
                  <FontAwesomeIcon icon={faFileAlt} />
                  &ensp; <span>Posts</span>
                </Link>
              </li>
              {user.role === "admin" && (
                <li
                  className={activeComponent === "reviewblog" ? "active" : ""}
                >
                  <Link
                    to="/admin/reviewblog"
                    onClick={() => setActiveComponent("reviewblog")}
                    data-description="Review Blog"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                    &ensp; <span>Review Blog</span>
                  </Link>
                </li>
              )}
              <li className={activeComponent === "users" ? "active" : ""}>
                <Link
                  to="/admin/users"
                  onClick={() => setActiveComponent("users")}
                  data-description="Users"
                >
                  <FontAwesomeIcon icon={faUsers} />
                  &ensp; <span>Users</span>
                </Link>
              </li>
              <li className={activeComponent === "settings" ? "active" : ""}>
                <Link
                  to="/admin/settings"
                  onClick={() => setActiveComponent("settings")}
                  data-description="Settings"
                >
                  <FontAwesomeIcon icon={faCog} />
                  &ensp; <span>Settings</span>
                </Link>
              </li>
              <li className={activeComponent === "reports" ? "active" : ""}>
                <Link
                  to="/admin/reports"
                  onClick={() => setActiveComponent("reports")}
                  data-description="Reports"
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  &ensp; <span>Reports</span>
                </Link>
              </li>
              <li className={activeComponent === "tools" ? "active" : ""}>
                <Link
                  to="/admin/tools"
                  onClick={() => setActiveComponent("tools")}
                  data-description="Tools"
                >
                  <FontAwesomeIcon icon={faTools} />
                  &ensp; <span>Tools</span>
                </Link>
              </li>
              {/* Add multilevel menu items here */}
              <li className={open["nested1"] ? "open" : ""}>
                <a
                  href="#!"
                  onClick={() => handleToggle("nested1")}
                  data-description="Nested Menu"
                >
                  <FontAwesomeIcon icon={faTools} />
                  &ensp; <span>Nested Menu</span>
                </a>
                <Collapse in={open["nested1"]}>
                  <ul>
                    <li
                      className={activeComponent === "subitem1" ? "active" : ""}
                    >
                      <Link
                        to="/admin/subitem1"
                        onClick={() => setActiveComponent("subitem1")}
                      >
                        Subitem 1
                      </Link>
                    </li>
                    <li
                      className={activeComponent === "subitem2" ? "active" : ""}
                    >
                      <Link
                        to="/admin/subitem2"
                        onClick={() => setActiveComponent("subitem2")}
                      >
                        Subitem 2
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
            </ul>
          ) : (
            <p>Access denied. Admin only.</p>
          )}
        </nav>
        <div className="admin-sidebar-footer">
          <p></p>
        </div>
      </aside>
      <main className="admin-content">{renderActiveComponent()}</main>
    </div>
  );
};

export default AdminLayout;
