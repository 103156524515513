import React, { useState, useEffect } from "react";
import apiService from "../ApiService";
import { Spinner } from "react-bootstrap";
import SEO from "../common/SEO"; // Import the SEO component
import { baseUrl } from "../config";
import Badge from "react-bootstrap/Badge";
import { trackEvent } from "../components/trackingUtils";
const SeoMetaChecker = () => {
  const [url, setUrl] = useState("");
  const [seoData, setSeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seoScore, setScore] = useState(0);
  const [scoredetails, setScoreDetails] = useState();

  useEffect(() => {
    trackEvent("PageLoad", "", "SEOMetaChecker");
  }, []);
  const handleFetchSeoData = async () => {
    setLoading(true);
    try {
      const response = await apiService.fetchSEOData(url); // Assuming apiService is correctly implemented
      trackEvent("Click", url, response.status);
      if (response.status === "success") {
        setSeoData(response.audit_report);
        setScore(response.audit_report.seo_score);
        setScoreDetails(response.score_details);
        setError(null);
      } else {
        setError("Failed to fetch SEO data");
        setSeoData(null);
      }
    } catch (error) {
      setError("Failed to fetch SEO data");
      setSeoData(null);
    } finally {
      setLoading(false);
    }
  };

  // Function to determine the color based on score
  const getScoreColor = () => {
    const score = seoScore;
    if (score >= 20) return "bg-success"; // High score
    if (score >= 10) return "bg-warning"; // Medium score
    return "bg-danger"; // Low score
  };

  return (
    <>
      <SEO
        title="SEO Navigator - Free Website Analysis & Insights | GeniusNexa"
        description={`Perform a comprehensive SEO audit with our free SEO Navigator. Identify website issues, optimize content, and improve rankings. Start your SEO analysis now.`}
        keywords="SEO Navigator, free SEO analysis, website SEO audit, SEO optimization, improve SEO rankings, SEO tool, SEO checker, website analysis tool"
        url={baseUrl + "/tool/seonavigator"}
        image={baseUrl + "/images/seonavigator.png"}
      />

      <div className="seo-meta-checker container">
        {seoData && (
          <div className={`score-card ${getScoreColor()} mb-4 p-3 text-white`}>
            <h3>SEO Score: {seoScore}</h3>
            <p>Based on SEO best practices</p>
          </div>
        )}
        <h2 className="mt-4 mb-4">SEO Navigator</h2>
        <div className="mb-4">
          <label htmlFor="urlInput" className="form-label">
            Enter URL:
          </label>
          <div className="input-group">
            <input
              type="text"
              id="urlInput"
              className="form-control"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="https://www.example.com"
              aria-label="Enter URL"
              aria-describedby="fetchButton"
            />
            <button
              className="btn btn-primary"
              type="button"
              id="fetchButton"
              onClick={handleFetchSeoData}
              disabled={!url || loading}
            >
              {loading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Fetch SEO Data"
              )}
            </button>
          </div>
        </div>
        {error && <p className="alert alert-danger">{error}</p>}
        {seoData && (
          <div className="seo-data">
            <div className="card mb-4">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="card-title">Title:</h3>
                  <p className="card-text">{seoData.title}</p>
                </div>
                <Badge
                  bg={
                    scoredetails.title_length_score >= 10
                      ? "success"
                      : scoredetails.title_length_score >= 5
                      ? "warning"
                      : "danger"
                  }
                >
                  {scoredetails.title_length_score}
                </Badge>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="card-title">Meta Description:</h3>
                  <p className="card-text">{seoData.meta_description}</p>{" "}
                </div>
                <Badge
                  bg={
                    scoredetails.meta_description_present_score >= 10
                      ? "success"
                      : scoredetails.meta_description_present_score >= 5
                      ? "warning"
                      : "danger"
                  }
                >
                  {scoredetails.meta_description_present_score}
                </Badge>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body justify-content-between align-items-center">
                <h3 className="card-title">Meta Keywords:</h3>
                <ul className="list-unstyled">
                  {seoData.meta_keywords.map((keyword, index) => (
                    <li key={index}>{keyword}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title">Open Graph Properties:</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Image:</strong>{" "}
                    <img
                      src={seoData.og_properties.og_image}
                      alt="OG "
                      className="img-fluid"
                    />
                  </li>
                  <li>
                    <strong>Title:</strong> {seoData.og_properties.og_title}
                  </li>
                  <li>
                    <strong>URL:</strong> {seoData.og_properties.og_url}
                  </li>
                </ul>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title">Twitter Properties:</h3>
                <ul className="list-unstyled">
                  <li>
                    <strong>Title:</strong>{" "}
                    {seoData.twitter_properties.twitter_title}
                  </li>
                  <li>
                    <strong>Description:</strong>{" "}
                    {seoData.twitter_properties.twitter_description}
                  </li>
                  <li>
                    <strong>Creator:</strong>{" "}
                    {seoData.twitter_properties.twitter_creator}
                  </li>
                  <li>
                    <strong>Image:</strong>{" "}
                    <img
                      src={seoData.twitter_properties.twitter_image}
                      alt="Twitter "
                      className="img-fluid"
                    />
                  </li>
                  <li>
                    <strong>Site:</strong>{" "}
                    {seoData.twitter_properties.twitter_site}
                  </li>
                  <li>
                    <strong>Card:</strong>{" "}
                    {seoData.twitter_properties.twitter_card}
                  </li>
                </ul>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title">WhatsApp Properties:</h3>
                <ul className="list-unstyled">
                  {seoData.whatsapp_properties.map((link, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title">Teams Properties:</h3>
                <ul className="list-unstyled">
                  {seoData.teams_properties.map((link, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body d-flex justify-content-between align-items-start">
                <div>
                  <h3 className="card-title">Internal Links:</h3>
                  <div>
                    <ul className="list-unstyled">
                      {seoData.internal_links.map((link, index) => (
                        <li key={index}>
                          <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {link}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <Badge
                  bg={
                    scoredetails.num_internal_links_score >= 10
                      ? "success"
                      : scoredetails.num_internal_links_score >= 5
                      ? "warning"
                      : "danger"
                  }
                >
                  {scoredetails.num_internal_links_score}
                </Badge>
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-body">
                <h3 className="card-title">External Links:</h3>
                <ul className="list-unstyled">
                  {seoData.external_links.map((link, index) => (
                    <li key={index}>
                      <a href={link} target="_blank" rel="noopener noreferrer">
                        {link}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {/* Display a message while loading */}
        {loading && (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading SEO data...</p>
          </div>
        )}
        {/* Display a message if no SEO data available */}
        {!loading && !seoData && (
          <div className="text-center">
            <p>
              No SEO data available. Enter a valid URL and click 'Fetch SEO
              Data'.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SeoMetaChecker;
