import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Dropdown, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faTimes,
  faUpload,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "./PostForm.css"; // Ensure this is imported
import apiService from "../../ApiService";

const PostForm = ({
  post,
  onSave,
  onCancel,
  BlogID,
  UserID,
  Status,
  blogsData,
}) => {
  const [selectedBlogId, setSelectedBlog] = useState(null);
  const [selectedBlogTitle, setSelectedBlogTitle] = useState("Select");
  const [formData, setFormData] = useState({
    Title: post?.Title || "",
    SEOUrl: post?.SEOUrl ? post?.SEOUrl.replace(/\s+/g, "-") : "",
    MetaDescription: post?.MetaDescription || "",
    MetaKeywords: post?.MetaKeywords || "",
    Content: post?.Content || "",
    ImageUrl: post?.ImageUrl || null,
    TwitterImageUrl: post?.TwitterImageUrl || null,
    FacebookImageUrl: post?.FacebookImageUrl || null,
    WhatsappImageUrl: post?.WhatsappImageUrl || null,
    LinkedinImageUrl: post?.LinkedinImageUrl || null,
    TeamsImageUrl: post?.TeamsImageUrl || null,
    WindowsIcon: post?.WindowsIcon || null,
    PhotoCredit: post?.PhotoCredit || null,
    AppleTouchIcon: post?.AppleTouchIcon || null,
    AndroidIcon: post?.AndroidIcon || null,
    BlogID: BlogID,
    UserID: UserID,
    Status: 0,
  });
  const [showHtmlModal, setShowHtmlModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState(formData.Content);
  useEffect(() => {
    function initalization() {
      console.log("blog id" + BlogID);
      const selectedBlog = blogsData?.find((blog) => blog.BlogID === BlogID);
      setSelectedBlog(BlogID);
      setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Select");
    }
    initalization();
  }, [BlogID, blogsData]);
  const handleChange = (value) => {
    setFormData({ ...formData, Content: value || "" });
  };
  const handleSelectBlog = async (blogId, event) => {
    blogId = parseInt(blogId); // Convert blogId to an integer
    setSelectedBlog(blogId);
    const selectedBlog = blogsData?.find((blog) => blog.BlogID === blogId);
    setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Filter Items");
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const formData = new FormData();
        formData.append("image", file);

        // Assuming `uploadImage` is a function that makes the API request
        const response = await uploadImage(formData);

        // Check for errors in the response
        if (response.error) {
          throw new Error(response.error); // Throw error to be caught by catch block
        }
        // Convert arrays to comma-separated values
        const windowsIcons = response.socialMediaPaths["WindowsIcon"]
          ? response.socialMediaPaths["WindowsIcon"].join(",")
          : "";
        const appleTouchIcons = response.socialMediaPaths["AppleTouchIcon"]
          ? response.socialMediaPaths["AppleTouchIcon"].join(",")
          : "";
        const AndroidIcons = response.socialMediaPaths["AndroidIcon"]
          ? response.socialMediaPaths["AndroidIcon"].join(",")
          : "";

        // Update form data with the response data
        setFormData((prevFormData) => ({
          ...prevFormData,
          ImageUrl: response.filePath || prevFormData.ImageUrl,
          TwitterImageUrl:
            response.socialMediaPaths["twitter"] ||
            prevFormData.TwitterImageUrl,
          FacebookImageUrl:
            response.socialMediaPaths["facebook"] ||
            prevFormData.FacebookImageUrl,
          WhatsappImageUrl:
            response.socialMediaPaths["whatsapp"] ||
            prevFormData.WhatsappImageUrl,
          LinkedinImageUrl:
            response.socialMediaPaths["linkedin"] ||
            prevFormData.LinkedinImageUrl,
          TeamsImageUrl:
            response.socialMediaPaths["teams"] || prevFormData.TeamsImageUrl,
          WindowsIcon: windowsIcons,
          AppleTouchIcon: appleTouchIcons,
          AndroidIcon: AndroidIcons,
        }));

        console.log("Image uploaded successfully!");
      } catch (error) {
        console.error("Error uploading image:", error.message);

        // Optionally, you can show an error message to the user
        alert("There was an error uploading the image: " + error.message);
      }
    } else {
      alert("No file selected. Please select an image file to upload.");
    }
  };

  const handleShowHtmlModal = () => {
    setHtmlContent(formData.Content); // Set the modal content to current editor content
    setShowHtmlModal(true);
  };

  const handleHtmlContentChange = (e) => {
    setHtmlContent(e.target.value);
  };

  const handleSaveHtmlContent = () => {
    setFormData({ ...formData, Content: htmlContent });
    setShowHtmlModal(false);
  };

  const uploadImage = async (formData) => {
    try {
      const response = await apiService.uploadImage(formData);
      return response; // Assuming API returns image path
    } catch (error) {
      console.error("Error uploading image:", error.message);
      throw error;
    }
  };

  const handleRemoveImage = async () => {
    try {
      if (formData.ImageUrl) {
        const imageFileName = formData.ImageUrl.split("/").pop();
        // Remove image from server
        await apiService.removeImage(imageFileName);

        // Update local state to remove image URL
        setFormData((prevFormData) => ({
          ...prevFormData,
          ImageUrl: null,
          TwitterImageUrl: null,
          FacebookImageUrl: null,
          WhatsappImageUrl: null,
          LinkedinImageUrl: null,
          TeamsImageUrl: null,
          WindowsIcon: null,
          AppleTouchIcon: null,
          AndroidIcons: null,
        }));
      }
    } catch (error) {
      console.error("Error removing image:", error.message);
    }
  };

  const handleSubmit = async (e, approved) => {
    debugger;
    e.preventDefault();
    try {
      if (
        !formData.Title ||
        !formData.SEOUrl ||
        !formData.MetaDescription ||
        !formData.MetaKeywords ||
        !formData.Content
      ) {
        // Highlight fields and prevent submission if any required field is empty
        alert("Please fill in all fields.");
        return;
      }

      if (post.PostID != null) {
        console.log(Status);
        const updatedPost = {
          ...formData,
          PostID: post.PostID,
          UserID: UserID,
          BlogID: selectedBlogId,
          LikeCount: 0,
          DislikeCount: 0,
          Status: approved,
        };
        await apiService.updateBlogPost(updatedPost, post.PostID, Status);
      } else {
        // Create new post
        const newPost = {
          ...formData,
          UserID: UserID,
          BlogID: selectedBlogId,
          LikeCount: 0,
          DislikeCount: 0,
          Status: approved,
        };
        await apiService.createBlogPost(newPost);
      }
      onSave();
    } catch (error) {
      console.error("Error saving post:", error.message);
      // Handle error, show error message, etc.
    }
  };

  return (
    <div className="post-form">
      <div className="post-form-header">
        <Row>
          <Col xs={12} md={8} lg={8}>
            <EditorToolbar />
          </Col>
          <Col xs={12} md={4} lg={4} className="btn-actions">
            {Status === "0" ? (
              <>
                <Button
                  variant="warning"
                  onClick={(e) => handleSubmit(e, 2)}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faSave} className="me-1" />
                  Publish
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => handleSubmit(e, 0)}
                  className="me-2"
                >
                  <FontAwesomeIcon icon={faSave} className="me-1" />
                  Save
                </Button>
              </>
            ) : Status === "3" ? (
              <Button
                variant="warning"
                onClick={(e) => handleSubmit(e, 2)}
                className="me-2"
              >
                <FontAwesomeIcon icon={faSave} className="me-1" />
                Publishss
              </Button>
            ) : (
              <Button
                variant="warning"
                onClick={(e) => handleSubmit(e, Status)}
                className="me-2"
              >
                <FontAwesomeIcon icon={faSave} className="me-1" />
                Publish
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={handleShowHtmlModal}
              className="me-2"
            >
              <FontAwesomeIcon icon={faEdit} className="me-1" />
              Edit Html
            </Button>
            <Button variant="secondary" onClick={onCancel}>
              <FontAwesomeIcon icon={faTimes} className="me-1" />
              Cancel
            </Button>
          </Col>
        </Row>
      </div>

      <div className="post-form-body">
        <Form onSubmit={handleSubmit}>
          {post && post.PostID && Status !== "0" ? (
            <Row className="mb-3">
              <Col xs={12} md={4} lg={4}>
                <Dropdown onSelect={handleSelectBlog}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedBlogTitle}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {blogsData?.map((blog) => (
                      <Dropdown.Item key={blog.BlogID} eventKey={blog.BlogID}>
                        {blog.Title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          ) : (
            <>
              <Row className="mb-3">
                <Col xs={12} md={4} lg={4}>
                  <Dropdown onSelect={handleSelectBlog}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {selectedBlogTitle}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {blogsData.map((blog) => (
                        <Dropdown.Item key={blog.BlogID} eventKey={blog.BlogID}>
                          {blog.Title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.Title}
                    onChange={(e) =>
                      setFormData({ ...formData, Title: e.target.value })
                    }
                    required
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Form.Control
                    type="text"
                    name="seoUrl"
                    placeholder="SEO URL"
                    value={formData.SEOUrl}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        SEOUrl: e.target.value.replace(/\s+/g, "-"),
                      })
                    }
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={12} md={4} lg={4}>
                  <Form.Control
                    type="text"
                    name="metaDescription"
                    placeholder="Meta Description"
                    value={formData.MetaDescription}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        MetaDescription: e.target.value,
                      })
                    }
                    required
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Form.Control
                    type="text"
                    name="metaKeywords"
                    placeholder="Meta Keywords"
                    value={formData.MetaKeywords}
                    onChange={(e) =>
                      setFormData({ ...formData, MetaKeywords: e.target.value })
                    }
                    required
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <Form.Control
                    type="text"
                    name="PhotoCredit"
                    placeholder="Image Credit"
                    value={formData.PhotoCredit || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, PhotoCredit: e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="al-center d-flex backcolor">
                  <div
                    className={`image-upload-container al-center ${
                      formData.ImageUrl ? "uploaded" : ""
                    }`}
                  >
                    {formData.ImageUrl ? (
                      <div className="uploaded-image position-relative">
                        <img
                          src={formData.ImageUrl}
                          alt="Uploaded"
                          style={{
                            maxWidth: "720px",
                            height: "290px",
                            margin: "auto",
                          }}
                        />
                        <Button
                          variant="danger"
                          onClick={handleRemoveImage}
                          className="remove-image-button"
                        >
                          Remove Image
                        </Button>
                      </div>
                    ) : (
                      <div
                        className="upload-prompt"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleImageUpload}
                      >
                        <Form.Label className="custom-file-upload">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageUpload}
                            required
                          />
                          <FontAwesomeIcon icon={faUpload} className="me-2" />
                          Drop your image here or click to upload
                        </Form.Label>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>{" "}
            </>
          )}
          <Row>
            <Col>
              <ReactQuill
                value={formData.Content}
                onChange={handleChange}
                modules={modules}
                formats={formats}
                className="bg-white"
                placeholder="Write your content here"
                required
              />
            </Col>
          </Row>

          <Button type="submit" style={{ display: "none" }}></Button>
        </Form>
      </div>

      <Modal
        show={showHtmlModal}
        dialogClassName="my-modal"
        onHide={() => setShowHtmlModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit HTML Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              as="textarea"
              rows={10}
              value={htmlContent}
              onChange={handleHtmlContentChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowHtmlModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveHtmlContent}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PostForm;
