import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTrash } from "@fortawesome/free-solid-svg-icons";
import apiService from "../ApiService";

const ImageUpload = ({ onImageUpload, onUploadStart }) => {
  const [imageUrl, setImageUrl] = useState(null);

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      onUploadStart(); // Notify parent component to show spinner
      try {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("uploadDir", "../temp/"); // Specify the upload directory
        const response = await apiService.uploadImage(formData);
        setImageUrl(response.filePath);
        onImageUpload(response.filePath, response.socialMediaPaths);
      } catch (error) {
        console.error("Error uploading image:", error.message);
        onImageUpload(null, null, true); // Set error state
      }
    }
  };

  const handleRemoveImage = async () => {
    try {
      if (imageUrl) {
        const imageFileName = imageUrl.split("/").pop();
        await apiService.removeImage(imageFileName, "../temp/");
        setImageUrl(null);
        onImageUpload(null, {
          twitter: null,
          facebook: null,
          whatsapp: null,
          linkedin: null,
        });
      }
    } catch (error) {
      console.error("Error removing image:", error.message);
    }
  };

  return (
    <div className="image-upload-container">
      {imageUrl ? (
        <div className="uploaded-image">
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{ maxWidth: "100%", height: "auto" }}
          />
          <Button variant="danger" onClick={handleRemoveImage}>
            <FontAwesomeIcon icon={faTrash} /> Remove Image
          </Button>
        </div>
      ) : (
        <div className="upload-prompt">
          <Form.Label className="custom-file-upload">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required
            />
            <FontAwesomeIcon icon={faUpload} className="me-2" />
            Drop your image here or click to upload
          </Form.Label>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
