import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Pagination,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import "./ReviewBlogs.css";
import PostForm from "./PostForm";
import apiService from "../../ApiService";
import { formatDateTime } from "../../utils/DateUtils";
import PostView from "./PostView";

const ReviewBlogs = ({ user }) => {
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [posts, setPosts] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [editPost, setEditPost] = useState({
    PostID: null,
    BlogID: null,
    Title: "",
    Content: "",
    SEOUrl: "",
    MetaDescription: "",
    MetaKeywords: "",
    ImageUrl: null,
    TwitterImageUrl: null,
    FacebookImageUrl: null,
    WhatsappImageUrl: null,
    LinkedinImageUrl: null,
    Status: 0,
  });

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedBlogTitle, setSelectedBlogTitle] = useState("Filter Items");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [postToApprove, setPostToApprove] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false); // State for loading page change

  // Preview state
  const [showPreview, setShowPreview] = useState(false);
  const [previewPost, setPreviewPost] = useState(null);

  const fetchBlogPostsByCategory = useCallback(
    async (category, status, page, pagesize, searchTitle) => {
      try {
        const pageSizeToUse = pagesize === undefined ? itemsPerPage : pagesize;
        setLoadingPage(true); // Start loading page change
        const response = await apiService.fetchBlogPostByCategory(
          category,
          page,
          pageSizeToUse,
          searchTitle,
          status
        );
        if (response.length === 0) {
          setPosts([]);
          setError("No posts found.");
        } else {
          setPosts(response.posts);
          setTotalPages(response.totalPages); // Assuming the API returns the total number of pages
          setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPosts([]);
          setError("No items found.");
        } else {
          setError(`${error.message}`);
          setPosts([]);
        }
      } finally {
        setLoadingPage(false); // Stop loading page change
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const loadBlogs = async () => {
      try {
        const data = await apiService.fetchBlog();
        setBlogs(data);
        if (data && data.length > 0) {
          const firstBlogId = data[0].BlogID;
          setSelectedBlog(firstBlogId);
          setSelectedBlogTitle(data[0].Title);
          fetchBlogPostsByCategory(data[0].SEOUrl, 2, 1);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    loadBlogs();
  }, [fetchBlogPostsByCategory]);

  const handleEditPost = (post) => {
    setEditPost(post);
    setShowEditor(true);
  };

  const handleApprovePost = async (PostID) => {
    try {
      setPostToApprove(PostID);
      setShowApproveModal(true);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    }
  };

  const confirmApprove = async () => {
    try {
      await apiService.approveRejectArticle(postToApprove, 1);
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    } finally {
      setShowApproveModal(false);
    }
  };
  const handleCancelModal = () => {
    setPostToApprove(null);
    setShowApproveModal(false);
  };
  const handlePreview = (post) => {
    setPreviewPost(post);
    setShowPreview(true);
    setShowEditor(false);
  };

  const confirmReject = async () => {
    try {
      await apiService.approveRejectArticle(postToApprove, 3);
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    } finally {
      setShowApproveModal(false);
    }
  };

  const handleSelectItemsPerPage = async (eventKey) => {
    const svalue = parseInt(eventKey, 10);
    setItemsPerPage(svalue);
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage, svalue); // Fetch with current page
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSavePost = async (formData) => {
    setShowEditor(false);
    setEditPost({
      PostID: null,
      Title: "",
      Content: "",
      SEOUrl: "",
      MetaDescription: "",
      MetaKeywords: "",
      ImageUrl: null,
      TwitterImageUrl: null,
      FacebookImageUrl: null,
      WhatsappImageUrl: null,
      LinkedinImageUrl: null,
      BlogID: null,
      Status: 0,
    });
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, currentPage);
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSelectBlog = async (blogId, event) => {
    blogId = parseInt(blogId); // Convert blogId to an integer
    setSelectedBlog(blogId);
    const selectedBlog = blogs.find((blog) => blog.BlogID === blogId);
    setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Filter Items");

    try {
      await fetchBlogPostsByCategory(selectedBlog.SEOUrl, 2, 1); // Reset to page 1
      setCurrentPage(1);
    } catch (error) {
      // Handle error, if needed
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchTitle(event.target.value); // Update searchTitle state
    // Call API with searchTitle
    const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
    fetchBlogPostsByCategory(
      blogfind.SEOUrl,
      2,
      currentPage,
      itemsPerPage,
      event.target.value
    );
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      setLoadingPage(true); // Start loading page change
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 2, page);
    } catch (error) {
      setError(`Error fetching blog posts: ${error.message}`);
    } finally {
      setLoadingPage(false); // Stop loading page change
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        />
      );
      items.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return items;
  };

  return (
    <>
      {!showEditor && !showPreview && (
        <div className="gn-container adminpost">
          <div className="gn-header">
            <Row className="margin-0">
              <Col
                xs={12}
                md={6}
                lg={6}
                className="d-flex align-items-center mb-3 mb-md-0"
              >
                <div className="me-3">
                  <input
                    value={searchTitle}
                    type="text"
                    placeholder="Search..."
                    className="form-control"
                    onChange={handleSearchInputChange}
                  />
                </div>
                <Dropdown onSelect={handleSelectBlog}>
                  <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    {selectedBlogTitle}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {loading ? (
                      <Dropdown.Item disabled>Loading...</Dropdown.Item>
                    ) : (
                      blogs.map((blog) => (
                        <Dropdown.Item key={blog.BlogID} eventKey={blog.BlogID}>
                          {blog.Title}
                        </Dropdown.Item>
                      ))
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </div>

          <div className="gn-body">
            {loadingPage ? (
              <div className="text-center mt-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Row className="margin-0">
                {posts && posts.length > 0 ? (
                  posts.map((post) => (
                    <Col
                      key={post.PostID}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-4"
                    >
                      <div
                        className={`blog-post-admin ${
                          post.Status === 0 ? "not-approved" : ""
                        }`}
                      >
                        <h3>{post.Title}</h3>
                        <div className="blog-post-content-admin">
                          <div
                            dangerouslySetInnerHTML={{ __html: post.Content }}
                          />
                        </div>
                        <div className="post-meta">
                          <p className="author">{post.Author}</p>
                          <p className="published-date">
                            {formatDateTime(post.ModifiedDate)}
                          </p>
                        </div>
                        <div className="btn-group">
                          <Button
                            variant="primary"
                            onClick={() => handleEditPost(post)}
                          >
                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => handlePreview(post)}
                          >
                            <FontAwesomeIcon icon={faEye} className="me-2" />
                          </Button>
                          <Button
                            variant="success"
                            onClick={() => handleApprovePost(post.PostID)}
                          >
                            <FontAwesomeIcon icon={faCheck} className="me-2" />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : error ? (
                  <Col xs={12}>
                    <p className="text-danger">{error}</p>
                  </Col>
                ) : (
                  <Col xs={12}>
                    <p>No posts available.</p>
                  </Col>
                )}
              </Row>
            )}
          </div>
          <div className="gn-footer">
            <Row className="margin-0">
              <Col xs={6}>
                {" "}
                <Dropdown onSelect={handleSelectItemsPerPage}>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {itemsPerPage}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="6">6</Dropdown.Item>
                    <Dropdown.Item eventKey="9">9</Dropdown.Item>
                    <Dropdown.Item eventKey="12">12</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Pagination>{renderPaginationItems()}</Pagination>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {showEditor && (
        <Row className="mb-3 margin-0">
          <Col xs={12} className="padding-0">
            <div className="post-form-container">
              <PostForm
                post={editPost}
                onSave={handleSavePost}
                onCancel={() => setShowEditor(false)}
                BlogID={selectedBlog}
                UserID={user?.userId}
                Status={2}
                blogsData={blogs}
              />
            </div>
          </Col>
        </Row>
      )}
      {showPreview && (
        <PostView post={previewPost} onBack={() => setShowPreview(false)} />
      )}
      <Modal show={showApproveModal} onHide={handleCancelModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Approve/Reject</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please Approve or Reject Post?</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={confirmApprove}>
            Approve
          </Button>
          <Button variant="danger" onClick={confirmReject}>
            Reject
          </Button>
          <Button variant="warning" onClick={handleCancelModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReviewBlogs;
