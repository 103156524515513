// Services.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import SEO from "../../common/SEO"; // Ensure correct path
import "./Services.css"; // Ensure correct path for CSS
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";
const Services = ({ services }) => {
  let { url } = useParams();
  const service = services.find((service) => service.url === url);
  useEffect(() => {
    trackEvent("PageLoad", "", "Services"); // Track the page load event
  }, []);
  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <>
      <SEO
        title={service.title}
        description={service.description}
        keywords={service.keywords}
        image={service.imagefullurl}
        url={baseUrl + `/${service.url}`}
      />

      <div className="hero-image">
        <div className="overlay"></div> {/* Overlay for text */}
        <div className="hero-text">
          <h1>{service.title}</h1>
          <p>{service.description}</p>
        </div>
      </div>

      <div className="details-section container">
        {service.details &&
          service.details.map((detail, index) => (
            <div key={index}>
              <h2>{detail.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: detail.description }} />
            </div>
          ))}
      </div>
    </>
  );
};

export default Services;
