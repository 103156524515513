import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserProvider } from "./contexts/UserContext";

const rootElement = document.getElementById("root");

const app = (
  <UserProvider>
    <App />
  </UserProvider>
);

if (rootElement.hasChildNodes()) {
  hydrateRoot(rootElement, app);
} else {
  const root = createRoot(rootElement);
  root.render(app);
}
