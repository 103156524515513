import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import JSZip from "jszip";
import FileSaver from "file-saver";
import "./SocialMediaFormats.css";

const SocialMediaFormats = ({ socialMediaPaths }) => {
  const downloadImage = (url) => {
    console.log(url);
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadAll = async (paths, folderName) => {
    const zip = new JSZip();
    const folder = zip.folder(folderName);

    for (const [index, path] of paths.entries()) {
      try {
        const response = await fetch(path);
        const blob = await response.blob();
        folder.file(`image_${index + 1}_${path.split("/").pop()}`, blob);
      } catch (error) {
        console.error(`Failed to fetch image ${index}:`, error);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      FileSaver.saveAs(content, `${folderName}.zip`);
    });
  };

  return (
    <div className="social-media-formats">
      {Object.entries(socialMediaPaths).map(([category, paths]) => (
        <div key={category} className="social-media-category">
          <h3>{category.replace(/-/g, " ").toUpperCase()}</h3>
          {Array.isArray(paths) ? (
            <>
              {paths.map((path, index) => (
                <div key={index} className="social-media-image">
                  <img
                    src={path}
                    alt={`Social media ${index + 1} for ${category.replace(
                      /-/g,
                      " "
                    )}`}
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                  <Button variant="primary" onClick={() => downloadImage(path)}>
                    <FontAwesomeIcon icon={faDownload} /> Download {index + 1}
                  </Button>
                </div>
              ))}
              <Button
                variant="secondary"
                onClick={() => downloadAll(paths, category.replace(/-/g, "_"))}
                className="mt-3"
              >
                <FontAwesomeIcon icon={faFolderOpen} /> Download All{" "}
                {category.replace(/-/g, " ")}
              </Button>
            </>
          ) : (
            <div className="social-media-image">
              <img
                src={paths}
                alt={`Social media format for ${category.replace(/-/g, " ")}`}
                style={{ maxWidth: "100%", height: "auto" }}
              />
              <Button variant="primary" onClick={() => downloadImage(paths)}>
                <FontAwesomeIcon icon={faDownload} /> Download {category} format
              </Button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialMediaFormats;
