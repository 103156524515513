import qs from "query-string";
import { restApiUrl, basicAuth } from "./config";

const log = false; // Set to `false` to disable logs

const apiService = {
  async getEvents(fromDate = null, toDate = null) {
    try {
      // Construct the query string with optional date parameters
      const queryParams = new URLSearchParams();
      if (fromDate) queryParams.append("from", fromDate);
      if (toDate) queryParams.append("to", toDate);

      // Define the endpoint with the query parameters
      const endpoint = `${restApiUrl}/eventtrack?action=read&${queryParams.toString()}`;

      if (log) {
        console.log("Calling getEvents with endpoint: " + endpoint);
      }

      // Send a GET request to the endpoint
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: basicAuth, // Include authorization if required
          "Content-Type": "application/json", // Set the content type to JSON
        },
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();
      if (log) console.log("getEvents response received:", data);

      // Return the events data
      return data;
    } catch (error) {
      handleApiError(error, "Error fetching event tracking data");
    }
  },

  async postEventTracking(eventData) {
    try {
      // Define the endpoint for posting event tracking data
      const endpoint = `${restApiUrl}/eventtrack?action=create`;

      if (log) {
        console.log("Calling eventtrack with endpoint: " + endpoint);
        console.log("Event data:", eventData);
      }

      // Send a POST request to the endpoint with event data
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: basicAuth, // Include authorization if required
          "Content-Type": "application/json", // Set the content type to JSON
        },
        body: JSON.stringify(eventData), // Convert the event data to JSON
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();
      if (log) console.log("postEventTracking response received:", data);

      // Return a success message or data from the response
      return data;
    } catch (error) {
      handleApiError(error, "Error posting event tracking data");
    }
  },
  async fetchTotalPages() {
    try {
      // Define the endpoint for fetching the total number of pages
      const endpoint = `${restApiUrl}/pdf?action=getTotalPages`;

      if (log)
        console.log("Calling fetchTotalPages with endpoint: " + endpoint);

      // Send a GET request to the endpoint
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: basicAuth, // Include authorization if required
          "Content-Type": "application/json", // Set the content type to JSON for this request
        },
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response as JSON
      const data = await response.json();
      if (log) console.log("fetchTotalPages response received:", data);

      // Return the total number of pages
      return data.totalPages;
    } catch (error) {
      handleApiError(error, "Error fetching total number of pages");
    }
  },
  async fetchPdfPage(pageNumber) {
    try {
      // Define the endpoint for fetching the PDF page
      const endpoint = `${restApiUrl}/pdf?page=${pageNumber}`;

      if (log) console.log("Calling fetchPdfPage with endpoint: " + endpoint);

      // Send a GET request to the endpoint
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          Authorization: basicAuth, // Include authorization if required
          "Content-Type": "application/pdf", // Set the content type to PDF
        },
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Return the response as a Blob (for binary data like PDFs)
      const pdfBlob = await response.blob();
      if (log)
        console.log("fetchPdfPage response received for page:", pageNumber);

      // Create a URL for the Blob and return it
      const pdfUrl = URL.createObjectURL(pdfBlob);
      return pdfUrl;
    } catch (error) {
      handleApiError(error, "Error fetching PDF page");
    }
  },
  //subscribers
  async createSubscriber(email) {
    try {
      // Define the endpoint for creating subscribers
      const endpoint = `${restApiUrl}/subscribers`;

      if (log)
        console.log("Calling createSubscriber with endpoint: " + endpoint);

      // Send a POST request to the endpoint
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: basicAuth, // Include authorization if required
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Email: email }), // Convert the subscriber data to JSON
      });

      // Check if the response is successful
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse and return the response data as JSON
      const data = await response.json();
      if (log) console.log("createSubscriber response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error creating subscriber");
    }
  },

  //users
  async getUsers(page = 1, itemsPerPage = 10, role = "", search = "") {
    try {
      // Construct the endpoint URL with query parameters for pagination, search, and filter
      const endpoint = `${restApiUrl}/users?page=${page}&items_per_page=${itemsPerPage}&role=${encodeURIComponent(
        role
      )}&emailOrName=${encodeURIComponent(search)}`;

      if (log) console.log("Calling getUsers with endpoint: " + endpoint);

      // Fetch data from the API
      const response = await fetch(endpoint, {
        timeout: 60000, // 60 seconds
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });

      // Check if the response status is OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse JSON data from the response
      const data = await response.json();
      if (log) console.log("getUsers response:", data);

      // Return the users data
      return {
        records: data.records,
        totalPages: data.total_pages,
        currentPage: data.current_page,
      };
    } catch (error) {
      handleApiError(error, "Error fetching users");
      // Ensure to return a consistent structure even in error cases if needed
      return {
        records: [],
        totalPages: 0,
        currentPage: page,
      };
    }
  },
  async updateUser(data) {
    try {
      // Define the endpoint URL
      const endpoint = `${restApiUrl}/users`;

      // Log the data if needed
      if (log) console.log("Calling updateUser with data:", data);

      // Make the PUT request to the API endpoint
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "PUT",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      // Check if the response is OK
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Parse the response JSON
      const responseData = await response.json();

      // Log the response data if needed
      if (log) console.log("updateUser response:", responseData);

      // Return the response data
      return responseData;
    } catch (error) {
      // Handle any errors that occur during the fetch
      handleApiError(error, "Error updating user");
    }
  },
  async deleteUser(userId) {
    try {
      const endpoint = `${restApiUrl}/users?UserID=${userId}`;
      if (log) console.log("Calling deleteUser with endpoint:", endpoint);

      const response = await fetch(endpoint, {
        method: "DELETE",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (log) console.log("deleteUser response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error deleting user");
    }
  },

  //Reaction

  async createBlogReaction(blogPostData) {
    try {
      const endpoint = `${restApiUrl}/blogReactions`;
      if (log)
        console.log("Calling createBlogReaction with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(blogPostData),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("createBlogReaction response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error creating blog post");
    }
  },

  async deleteBlogReaction(reactionId) {
    try {
      const endpoint = `${restApiUrl}/blogReactions?id=${reactionId}`;
      if (log)
        console.log("Calling deleteBlogReaction with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "DELETE",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("deleteBlogReaction response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error deleting blog post");
    }
  },
  async getBlogReactionByPostId(postId) {
    try {
      const endpoint = `${restApiUrl}/blogReactions?postid=${postId}`;
      if (log)
        console.log(
          "Calling getBlogReactionByPostId with endpoint: " + endpoint
        );
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("getBlogReactionByPostId response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error fetching blog post by ID");
    }
  },

  async updateBlogReaction(reactionId, data) {
    try {
      const endpoint = `${restApiUrl}/blogReactions?id=${reactionId}`;
      if (log) console.log("Calling updateBlogReaction with data:", data);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "PUT",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("updateBlogReaction response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error updating blog reaction");
    }
  },
  //blogpost
  async getBlogPost(category, seoUrl) {
    try {
      const endpoint = `${restApiUrl}/blogPosts?category=${category}&seourl=${seoUrl}`;
      if (log) console.log("Calling getBlogPost with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("getBlogPost response:", data);
      return data.records[0];
    } catch (error) {
      handleApiError(error, "Error fetching blog post");
    }
  },

  async getBlogReactionById(postId) {
    try {
      const endpoint = `${restApiUrl}/blogReactions?postid=${postId}`;
      if (log)
        console.log("Calling getBlogPostById with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("getBlogPostById response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error fetching blog post by ID");
    }
  },

  async getBlogPostByPostID(PostID) {
    try {
      const endpoint = `${restApiUrl}/blogPosts?postid=${PostID}`;
      if (log)
        console.log("Calling getBlogPostByPostID with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("getBlogPostByPostID response:", data);
      return data.records[0];
    } catch (error) {
      handleApiError(error, "Error fetching blog post");
    }
  },

  async createBlogPost(data) {
    try {
      const endpoint = `${restApiUrl}/blogPosts`;
      if (log) console.log("Calling createBlogPost with data:", data);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("createBlogPost response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error creating blog post");
    }
  },

  async approveRejectArticle(PostID, Status) {
    try {
      const endpoint = `${restApiUrl}/approvereject?postid=${PostID}&status=${Status}`;
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("getBlogPostByPostID response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error updating blog post");
    }
  },

  async updateBlogPost(data, PostID, Published) {
    try {
      const endpoint = `${restApiUrl}/blogPosts?postid=${PostID}`;
      if (log) console.log("Calling updateBlogPost with data:", data);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "PUT",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("updateBlogPost response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error updating blog post");
    }
  },

  async deleteComment(commentId) {
    const payload = {
      CommentID: commentId,
    };
    const endpoint = `${restApiUrl}/comments`;
    if (log) console.log("Calling Delete comments with endpoint: " + endpoint);

    const response = await fetch(endpoint, {
      method: "DELETE",
      headers: {
        Authorization: basicAuth,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  },

  async postComment(postId, userId, content) {
    const payload = {
      PostID: postId,
      UserID: userId,
      Content: content,
    };
    const endpoint = `${restApiUrl}/comments`;
    if (log) console.log("Calling posting comments with endpoint: " + endpoint);

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: basicAuth,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  },

  async updateComment(commentId, userId, content) {
    const payload = {
      CommentID: commentId,
      UserID: userId,
      Content: content,
    };
    const endpoint = `${restApiUrl}/comments`;
    if (log) console.log("Calling posting comments with endpoint: " + endpoint);

    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        Authorization: basicAuth,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  },

  async deleteBlogPost(PostID, Status) {
    try {
      const endpoint = `${restApiUrl}/blogPosts?postid=${PostID}&status=${Status}`;
      if (log) console.log("Calling deleteBlogPost with endpoint: " + endpoint);
      const response = await fetch(endpoint, {
        method: "DELETE",
        timeout: 60000,
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("deleteBlogPost response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error deleting blog post");
    }
  },

  async uploadImage(formData) {
    try {
      const endpoint = `${restApiUrl}/uploadImage`;
      if (log) console.log("Calling uploadImage with formData:", formData);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("uploadImage response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error uploading image");
      throw error;
    }
  },

  async removeImage(filename, directory = "") {
    try {
      let endpoint = `${restApiUrl}/uploadImage?filename=${filename}`;
      if (directory) {
        endpoint += `&uploadDir=${encodeURIComponent(directory)}`;
      }
      console.log(endpoint);
      if (log) console.log("Calling removeImage with filename: " + filename);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "DELETE",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("removeImage response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error removing image");
    }
  },

  async fetchBlog() {
    const endpoint = `${restApiUrl}/blogs`;
    if (log) console.log("Calling fetchBlog with endpoint: " + endpoint);
    try {
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("fetchBlog response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error fetching blog posts");
      throw error;
    }
  },

  async fetchUrl() {
    const endpoint = `${restApiUrl}/url`;
    if (log) console.log("Calling fetchUrl with endpoint: " + endpoint);
    try {
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("fetchUrl response:", data);
      return data;
    } catch (error) {
      handleApiError(error, "Error fetching URL");
      throw error;
    }
  },

  async fetchBlogPostByCategory(
    category,
    currentpage = 1,
    pageSize = 6,
    searchTitle = "",
    status = 1
  ) {
    try {
      const endpoint = `${restApiUrl}/blogPosts?category=${category}&pageIndex=${currentpage}&pageSize=${pageSize}&searchTitle=${searchTitle}&status=${status}`;
      if (log)
        console.log(
          "Calling fetchBlogPostByCategory with endpoint: " + endpoint
        );
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "GET",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (log) console.log("fetchBlogPostByCategory response:", data);
      return data;
    } catch (error) {
      handleApiError(
        error,
        `Error fetching blog posts for category ${category}`
      );
      throw error;
    }
  },
  async socialLogin(action, Token) {
    try {
      const formData = qs.stringify({
        action: action,
        Token: Token,
      });
      const endpoint = `${restApiUrl}/login`;
      if (log) console.log("Calling login with formData:", formData);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("login response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error logging in");
    }
  },
  async login(email, password, action) {
    try {
      const formData = qs.stringify({
        action: action,
        useremail: email,
        password: password,
      });
      const endpoint = `${restApiUrl}/login`;
      if (log) console.log("Calling login with formData:", formData);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("login response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error logging in");
    }
  },

  async logout(sessionId) {
    try {
      const formData = qs.stringify({
        sessionId: sessionId,
      });
      const endpoint = `${restApiUrl}/logout`;
      if (log) console.log("Calling logout with formData:", formData);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("logout response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error logout in");
    }
  },

  async fetchSEOData($url) {
    const endpoint = `${restApiUrl}/python`;
    const data = { url: $url };
    const headers = {
      "Content-Type": "application/json",
      Authorization: basicAuth, // Replace with your actual authorization token
    };

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      return responseData;
      // Handle the response data as needed
    } catch (error) {
      console.error("Fetch error:", error);
    }
  },

  async checkSession(sessionId) {
    try {
      const formData = qs.stringify({ sessionId: sessionId, action: "login" });
      const endpoint = `${restApiUrl}/login`;
      if (log) console.log("Calling checkSession with formData:", formData);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("checkSession response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error checking session");
    }
  },

  async sendEmail(data) {
    try {
      const formData = qs.stringify(data);
      const endpoint = `${restApiUrl}/sendEmail`;
      if (log) console.log("Calling sendEmail with data:", data);
      const response = await fetch(endpoint, {
        timeout: 60000,
        method: "POST",
        headers: {
          Authorization: basicAuth,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      if (log) console.log("sendEmail response:", responseData);
      return responseData;
    } catch (error) {
      handleApiError(error, "Error sending email");
    }
  },
};

function handleApiError(error, defaultMessage) {
  let errorMessage = defaultMessage;
  if (error.response) {
    errorMessage += `: ${
      error.response.data.message || error.response.statusText
    }`;
  } else {
    errorMessage += `: ${error.message}`;
  }
  console.error(errorMessage);
  throw new Error(errorMessage); // Optionally throw the error to propagate it further
}

export default apiService;
