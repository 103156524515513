import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { baseUrl } from "../../config";
import apiService from "../../ApiService";
import "./BlogPost.css";
import SEO from "../../common/SEO";
import { trackEvent } from "../../components/trackingUtils";
const dummyImageUrl = "https://via.placeholder.com/400x200?text=Dummy+Image";

const BlogPost = ({ SEOUrl, ...props }) => {
  const [filteredPost, setFilteredPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(1); // State to store total pages

  useEffect(() => {
    trackEvent("PageLoad", "", "BlogPost");
    const fetchFilteredPosts = async () => {
      setLoading(true);
      try {
        const data = await apiService.fetchBlogPostByCategory(SEOUrl, page);
        setTotalPages(data.totalPages); // Update total pages from API response
        if (data.posts && data.posts.length === 0) {
          setHasMore(false); // No more items, stop pagination
          if (page === 1) {
            setError("No items found."); // Show error if no items found on the first page
          }
        } else {
          if (page === 1) {
            setFilteredPost(data.posts);
          } else {
            setFilteredPost((prevPosts) => [...prevPosts, ...data.posts]);
          }
          setError(null); // Clear any existing error
        }
      } catch (error) {
        console.error("Error fetching filtered blog posts:", error);
        if (error.response && error.response.status === 404) {
          setHasMore(false); // No more items, stop pagination
          if (page === 1) {
            setError("No items found."); // Show error if no items found on the first page
          }
        } else {
          setError(`${error.message}`); // Set generic error message for other errors
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFilteredPosts();
  }, [SEOUrl, page]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const convertToTitleCase = (text) => {
    return text
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Add space between uppercase letters and lowercase letters
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  // Extracting keywords and description from the first post if available
  const firstPost = filteredPost.length > 0 ? filteredPost[0] : null;
  const seoKeywords = firstPost
    ? firstPost.keywords
    : "GeniusNexa blog, Nexa tech articles, Nexa technology trends";
  const seoDescription = firstPost
    ? firstPost.description
    : "Discover tech insights, tutorials, reviews, and industry updates on the GeniusNexa blog.";

  return (
    <div className="container blogpost">
      <SEO
        title={"GeniusNexa Blog: " + convertToTitleCase(SEOUrl)}
        description={seoDescription}
        keywords={seoKeywords}
        image={baseUrl + "/blogs/" + SEOUrl}
        url={baseUrl + "/blogs/" + SEOUrl}
      />
      <h1 className="mb-3">{convertToTitleCase(SEOUrl)}</h1>
      <Row className="g-4">
        {filteredPost &&
          filteredPost.map((post, idx) => (
            <Col key={idx} xs={12} sm={12} md={3} lg={4} className="mb-3">
              <Link
                to={`/blogs/${SEOUrl}/${post.SEOUrl}`}
                className="service-link"
              >
                <Card className="blog-card">
                  <div className="blog-card-image-wrapper">
                    <Card.Img
                      variant="top"
                      src={
                        post.ImageUrl
                          ? baseUrl + "/" + post.ImageUrl
                          : dummyImageUrl
                      }
                      alt={post.Title}
                      className="blog-card-image"
                    />
                  </div>

                  <Card.Body>
                    <Card.Title className="card-title">{post.Title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
      </Row>
      {loading && (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {page < totalPages && hasMore && !loading && !error && (
        <div className="text-center mt-3">
          <button className="btn btn-primary" onClick={handleLoadMore}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
