import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Register.css"; // Add your CSS file for styling
import apiService from "../../ApiService"; // Import the apiService

const Register = () => {
  const [username, setUsername] = useState("");
  const [useremail, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useHistory();

  const handleRegister = async (e) => {
    e.preventDefault();

    try {
      const data = await apiService.register(username, useremail, password);

      if (data.success) {
        alert("Registration successful!");
        navigate("/login"); // Redirect to login page after successful registration
      } else {
        setErrorMessage(data.message || "Registration failed");
      }
    } catch (error) {
      console.error("Error registering user:", error);
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="container">
      <div className="register-container">
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <h2>Register</h2>
        <form onSubmit={handleRegister}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={useremail}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Register</button>
        </form>
      </div>
    </div>
  );
};

export default Register;
