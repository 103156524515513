import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import apiService from "../../ApiService";
import "./Blog.css";
import SEO from "../../common/SEO"; // Import the SEO component
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";
//import ImageWithFallback from "../../components/ImageWithFallback";
const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    trackEvent("PageLoad", "", "Blog");
    const fetchBlogPosts = async () => {
      setLoading(true);
      try {
        const data = await apiService.fetchBlog();
        setBlogPosts(data);
        setError(null);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setError("Error fetching blog posts. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPosts();
  }, []);

  // Generate meta tags based on the blog posts
  const generateMetaTags = () => {
    if (blogPosts.length === 0) {
      return (
        <SEO
          title="GeniusNexa Blog - Latest Tech Insights"
          description={`Discover the latest tech insights, tutorials, and reviews on the GeniusNexa blog.`}
          keywords="Artificial intelligence advancements, Future of AI, AI trends, Healthy habits, Balanced lifestyle tips, Nutrition tips, Fitness routines, Personal finance tips, Finance management strategies, Budgeting tips, Investing strategies, Sustainable living guide, Eco-friendly practices, Green living tips, Trending news, Latest news updates, Microsoft Azure expert guide, Azure cloud services, Azure tips and tricks, Cloud application development"
          url={baseUrl + "/blogs"}
        />
      );
    }

    const firstPost = blogPosts[0];
    return (
      <SEO
        title="GeniusNexa Blog - Latest Tech Insights, Trends, and Innovations"
        description={`Discover the latest tech insights, tutorials, and reviews on the GeniusNexa blog. Featured post: ${firstPost.Title}`}
        keywords="GeniusNexa blog, tech articles, technology trends, tech tutorials, tech reviews, industry insights, best practices, developer blog, tech updates, innovation"
        image={baseUrl + "/images/blogs.png"}
        url={baseUrl + "/blogs"}
      />
    );
  };

  return (
    <div className="container blog-container">
      {generateMetaTags()} {/* Add SEO tags here */}
      {loading && (
        <div className="text-center mt-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      {!loading && (
        <>
          <h1>Blogs</h1>
          <Row xs={1} md={3} className="g-4">
            {blogPosts && blogPosts.length > 0 ? (
              blogPosts.map((post, idx) => (
                <Col key={idx} className="mb-3">
                  <Link to={`/blogs/${post.SEOUrl}`} className="service-link">
                    <Card className="h-100">
                      <Card.Img
                        variant="top"
                        src={
                          post.ImageUrl
                            ? baseUrl + "/" + post.ImageUrl
                            : `https://via.placeholder.com/150x150?text=${idx}`
                        }
                        alt={post.Title}
                        className="card-img-top"
                      />
                      <Card.Body>
                        <Card.Title>{post.Title}</Card.Title>
                        <Card.Text>{post.Description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </Col>
              ))
            ) : (
              <Col>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Text>No blog posts found.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default Blog;
