import React, { useEffect } from "react";
import SEO from "../../common/SEO"; // Assuming SEO component is defined elsewhere
import ServicesSection from "./Services";
import OperationModelSection from "./OperationModel";
import WhyChooseUsSection from "./WhyChooseUs";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
//import ServiceData from "../../Data/ServiceData"; // Assuming ServiceData is imported or defined elsewhere
import Category from "./Category";
import CEOQuotes from "./CEOQuotes";
import { baseUrl } from "../../config";
import { trackEvent } from "../../components/trackingUtils";
const Homepage = ({ services, blogs }) => {
  const quotes = [
    { text: "Quote 1 text...", author: "Author 1" },
    { text: "Quote 2 text...", author: "Author 2" },
    { text: "Quote 3 text...", author: "Author 3" },
    // Add more quotes as needed
  ];
  useEffect(() => {
    if (window.location.pathname === "/") {
      trackEvent("PageLoad", "", "Homepage");
    }
  }, []);
  return (
    <div>
      <SEO
        title="GeniusNexa - Digital Services, Consulting, and Innovation Hub"
        description="GeniusNexa is a pioneering force in digital services and consulting, driving innovation worldwide."
        keywords="GeniusNexa, Futuristic technologies, Cutting-edge advancements, Artificial intelligence (AI), Blockchain solutions, Technological revolution, Innovation, Automation, Robotics, Internet of Things (IoT), Software solutions, Digital transformation, Research and development, Client-centric solutions, Customized software, Cloud infrastructure, Agile methodology, Digital solutions, Business growth, Tailored solutions, Project management, Consulting services, Strategic planning, Application development, Continuous support, Technology pioneers, Industry leaders."
        image={baseUrl + "/favicon.png"}
        url={baseUrl}
      />
      <div className="hero-section">
        <div className="hero-overlay">
          <div className="hero-text">
            <h1>Transforming Ideas Into Innovation</h1>
            <p className="lead">
              At GeniusNexa, we're dedicated to transforming your digital
              aspirations into tangible realities.
            </p>
          </div>
        </div>
      </div>
      <ServicesSection services={services} />
      <OperationModelSection />
      <WhyChooseUsSection />
      <CEOQuotes quotes={quotes} />
      <Category blogs={blogs} />
      <AboutUs />
      <ContactUs />
    </div>
  );
};

export default Homepage;
