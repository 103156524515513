import React from "react";
import "./WhyChooseUs.css";
const WhyChooseUs = () => {
  return (
    <div className="container section-container">
      <h2 className="section-title">Why Choose Us?</h2>
      <div className="section-content">
        <p>
          At GeniusNexa, we stand out in the industry because of our commitment
          to delivering innovative and customized software solutions. Our team
          of experts brings extensive experience across various domains,
          ensuring that we can effectively meet the diverse needs of our
          clients.
        </p>
        <p>
          Whether you're aiming to enhance your cloud infrastructure, develop
          robust applications, or streamline your data processes, we possess the
          skills and expertise to help you succeed. We prioritize your success
          by embracing the latest technologies and delivering results that
          exceed expectations.
        </p>
        <p>
          Partner with GeniusNexa and let us guide you through the complexities
          of the digital world to achieve your business objectives with
          confidence.
        </p>
        <h5>Our Core Advantages:</h5>
        <ul>
          <li>
            <strong>Customized Solutions:</strong> Tailored software solutions
            designed to fit your specific business needs.
          </li>
          <li>
            <strong>Expertise Across Domains:</strong> Extensive experience in
            various industries ensures comprehensive understanding of your
            challenges.
          </li>
          <li>
            <strong>Technology Pioneers:</strong> Leveraging cutting-edge
            technologies to deliver innovative and efficient solutions.
          </li>
          <li>
            <strong>Client-Centric Approach:</strong> We prioritize your success
            by closely aligning with your goals and objectives.
          </li>
          <li>
            <strong>Commitment to Quality:</strong> Ensuring excellence in every
            project through rigorous quality assurance processes.
          </li>
          <li>
            <strong>Agile Methodology:</strong> Rapid deployment and iterative
            development to adapt to evolving business needs.
          </li>
        </ul>
        <p>
          At GeniusNexa, we are dedicated to empowering businesses with
          transformative digital solutions. Contact us today and discover how we
          can accelerate your growth and success in the digital age.
        </p>
      </div>
    </div>
  );
};

export default WhyChooseUs;
