import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Container } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "../ApiService";
import "./GNSubscribeModal.css";

const SubscribeModal = ({ useremail, show, handleClose }) => {
  const [email, setEmail] = useState("");
  useEffect(() => {
    if (useremail && useremail.trim() !== "") {
      setEmail(useremail);
    }
  }, [useremail]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiService.createSubscriber(email);
      // Assuming response is an object with a message property
      if (response && response.message) {
        toast.success(response.message);
      } else {
        toast.success("Successfully subscribed!");
      }
      handleClose();
    } catch (error) {
      // Show an error toast
      toast.error("Failed to subscribe. Please try again.");
      console.error("Subscription error:", error);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Modal show={show} onHide={handleClose} className="gn-subscribe-modal">
        <Modal.Header closeButton>
          <Modal.Title>Subscribe for Updates</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Subscribe
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SubscribeModal;
