import React from "react";
import "./Breadcrumbs.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faAngleRight } from "@fortawesome/free-solid-svg-icons"; // Import desired icons

const services = [
  // Define your services array here with valid routes and titles
];

const Breadcrumbs = () => {
  const { pathname } = useLocation();

  // Split pathname into segments and filter out empty segments
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  // Render null if on the root page
  if (pathname === "/") {
    return null;
  }

  let breadcrumbs = [];

  // Add Home breadcrumb
  breadcrumbs.push(
    <li key="home" className="breadcrumb-item">
      <Link to="/">
        <FontAwesomeIcon icon={faHome} /> Home
      </Link>
    </li>
  );

  // Construct breadcrumbs based on the path segments
  let currentPath = "/";
  pathSegments.forEach((segment, index) => {
    // Build the current path segment by segment
    currentPath += `${segment}/`;

    // Convert segment to readable text (capitalize first letter and replace hyphens with spaces)
    const text =
      segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, " ");

    // Check if segment matches a service URL
    const service = services.find((service) => service.url === segment);

    if (service) {
      // Render service breadcrumb
      breadcrumbs.push(
        <li key={service.url} className="breadcrumb-item">
          <Link to={currentPath}>
            <FontAwesomeIcon icon={faAngleRight} /> {service.title}
          </Link>
        </li>
      );
    } else {
      // Render regular breadcrumb link
      breadcrumbs.push(
        <li key={currentPath} className="breadcrumb-item">
          {/* Only render span for the last breadcrumb */}
          {index === pathSegments.length - 1 ? (
            <span>{text}</span>
          ) : (
            <Link to={currentPath}>
              <FontAwesomeIcon icon={faAngleRight} /> {text}
            </Link>
          )}
        </li>
      );
    }
  });

  return (
    <div className="background-container">
      <div className="container pb-2 pt-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">{breadcrumbs}</ol>
        </nav>
      </div>
    </div>
  );
};

export default Breadcrumbs;
