import React, { useContext, useState } from "react";
import { Navbar, Nav, Container, Dropdown, Offcanvas } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import "./Header.css";
import { useMediaQuery } from "react-responsive";
import { baseUrl } from "../../config";

const Header = ({ blogs }) => {
  const { user } = useContext(UserContext);
  const { isAuthenticated, logout } = useContext(UserContext);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1023 });
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  const handleDropdownClick = (name) => {
    setOpenDropdown((prev) => (prev === name ? null : name));
  };

  return (
    <>
      <Navbar bg="white" className="shadow" variant="white" expand="lg">
        <Container className="pb-0 pt-0">
          <Link
            to="/"
            className="navbar-brand d-flex flex-column align-items-center"
          >
            <img
              src={baseUrl + `/images/logo.png`}
              height="40"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <FontAwesomeIcon icon={faBars} onClick={toggleOffcanvas} />
          </Navbar.Toggle>
          {!isTabletOrMobile && (
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-end"
            >
              <Nav className="me-auto"></Nav>
              <Nav className="d-lg-flex align-items-center">
                <Link
                  to="/services"
                  className="nav-link menu-item"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Services
                </Link>
                <Dropdown as={Nav.Item} className="menu-item">
                  <Dropdown.Toggle as={Nav.Link} className="dropdown-toggle">
                    Blogs
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mega-menu">
                    {blogs.map((blog) => (
                      <Dropdown.Item
                        key={blog.SEOUrl}
                        as={Link}
                        to={`/blogs/${blog.SEOUrl}`}
                        className="blog-link"
                        onClick={() => setShowOffcanvas(false)}
                      >
                        {blog.Title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div className="divider"></div>
                <Dropdown as={Nav.Item} className="menu-item">
                  <Dropdown.Toggle as={Nav.Link} className="dropdown-toggle">
                    About
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mega-menu">
                    <Link
                      to="/careers"
                      className="dropdown-item"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Careers
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                <Link
                  to="/contact-us"
                  className="nav-link menu-item"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Contact Us
                </Link>
                <Dropdown as={Nav.Item} className="menu-item">
                  <Dropdown.Toggle as={Nav.Link} className="dropdown-toggle">
                    Tools
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="mega-menu">
                    <Dropdown.Item
                      as={Link}
                      to={`/tools/comparexpert`}
                      className="blog-link"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      CompareXpert
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`/tools/seonavigator`}
                      className="blog-link"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      SEO Navigator
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`/tools/imageconverter`}
                      className="blog-link"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      Image Converter
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {isAuthenticated ? (
                  <>
                    <Dropdown as={Nav.Item} className="menu-item">
                      <Dropdown.Toggle
                        as={Nav.Link}
                        className="dropdown-toggle"
                      >
                        <div className="user-avatar-container">
                          {user.userImage ? (
                            <img
                              src={user.userImage}
                              alt="User Avatar"
                              className="user-avatar"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              className="user-icon"
                            />
                          )}
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="mega-menu">
                        {user && user.role === "admin" && (
                          <Link
                            to="/admin"
                            className="dropdown-item"
                            onClick={() => setShowOffcanvas(false)}
                          >
                            Dashboard
                          </Link>
                        )}
                        <Link
                          to="/settings"
                          className="dropdown-item"
                          onClick={() => setShowOffcanvas(false)}
                        >
                          Settings
                        </Link>
                        <Link
                          to="/logout"
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                            setShowOffcanvas(false);
                          }}
                        >
                          Logout
                        </Link>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <Link
                    to="/login"
                    className="nav-link nav-link classic-color"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Sign Up
                  </Link>
                )}
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>

      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              src="/images/logo.png"
              height="40"
              className="d-inline-block align-top"
              alt="Logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Link
              to="/services"
              className="nav-link"
              onClick={() => setShowOffcanvas(false)}
            >
              Services
            </Link>

            <div>
              <Nav.Link
                className="nav-link dropdown-toggle"
                onClick={() => handleDropdownClick("blogs")}
              >
                Blogs
              </Nav.Link>
              {openDropdown === "blogs" && (
                <Nav className="flex-column pl-3 nested-menu">
                  {blogs.map((blog) => (
                    <Link
                      key={blog.SEOUrl}
                      to={`/blogs/${blog.SEOUrl}`}
                      className="nav-link"
                      onClick={() => setShowOffcanvas(false)}
                    >
                      {blog.Title}
                    </Link>
                  ))}
                </Nav>
              )}
            </div>

            <Link
              to="/careers"
              className="nav-link"
              onClick={() => setShowOffcanvas(false)}
            >
              Careers
            </Link>

            <Link
              to="/contact-us"
              className="nav-link"
              onClick={() => setShowOffcanvas(false)}
            >
              Contact Us
            </Link>

            <div>
              <Nav.Link
                className="nav-link dropdown-toggle"
                onClick={() => handleDropdownClick("tools")}
              >
                Tools
              </Nav.Link>
              {openDropdown === "tools" && (
                <Nav className="flex-column pl-3 nested-menu">
                  <Link
                    to="/tools/comparexpert"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    CompareXpert
                  </Link>
                  <Link
                    to="/tools/seonavigator"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    SEO Navigator
                  </Link>
                  <Link
                    to="/tools/imageconverter"
                    className="nav-link"
                    onClick={() => setShowOffcanvas(false)}
                  >
                    Image Converter
                  </Link>
                </Nav>
              )}
            </div>
            {isAuthenticated ? (
              <>
                <div className="divider"></div>
                <div>
                  <Nav.Link
                    className="nav-link dropdown-toggle"
                    onClick={() => handleDropdownClick("admin")}
                  >
                    Admin
                  </Nav.Link>
                  {openDropdown === "admin" && (
                    <Nav className="flex-column pl-3 nested-menu">
                      <Link
                        to="/admin"
                        className="nav-link"
                        onClick={() => setShowOffcanvas(false)}
                      >
                        Dashboard
                      </Link>
                    </Nav>
                  )}
                </div>
                <Link
                  to="/logout"
                  onClick={() => {
                    logout();
                    setShowOffcanvas(false);
                  }}
                  className="nav-link"
                >
                  Logout
                </Link>
              </>
            ) : (
              <Link
                to="/login"
                className="nav-link"
                onClick={() => setShowOffcanvas(false)}
              >
                Sign Up
              </Link>
            )}
          </Nav>
          {isAuthenticated && (
            <div className="offcanvas-user-avatar-container">
              {user.userImage ? (
                <img
                  src={user.userImage}
                  alt="User Avatar"
                  className="user-avatar"
                />
              ) : (
                <FontAwesomeIcon icon={faUser} className="user-icon" />
              )}
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Header;
