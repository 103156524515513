import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  Pagination,
  Modal,
  ToggleButton,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faPlus,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./Users.css";
import apiService from "../../ApiService";
import EditUserForm from "./EditUserForm";
import { useHistory } from "react-router-dom";

const AdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTitle, setSearchTitle] = useState("");
  const [statusValue, setStatusValue] = useState("ALL");
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [editingUserId, setEditingUserId] = useState(null);
  const history = useHistory();

  const radios = [
    { name: "ALL", value: "ALL" },
    { name: "Admin", value: "Admin" },
    { name: "Users", value: "User" },
  ];

  const fetchUsers = useCallback(async (page, pageSize, role, search) => {
    try {
      const response = await apiService.getUsers(page, pageSize, role, search);
      setUsers(response.records);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }, []);

  useEffect(() => {
    fetchUsers(currentPage, itemsPerPage, statusValue, searchTitle);
  }, [fetchUsers, currentPage, itemsPerPage, statusValue, searchTitle]);

  const handleSearchInputChange = (e) => {
    setSearchTitle(e.target.value);
    setCurrentPage(1);
  };

  const handleRadioChange = (value) => {
    setStatusValue(value);
    setCurrentPage(1);
  };

  const handleAddUser = () => {
    history.push("/register");
  };

  const handleEditUser = (userId) => {
    setEditingUserId(userId);
  };

  const handleDeleteUser = (userId) => {
    setDeleteUserId(userId);
    setShowDeleteModal(true);
  };

  const handleSelectItemsPerPage = (number) => {
    setItemsPerPage(parseInt(number, 10));
    setCurrentPage(1);
  };

  const confirmDeletePost = async () => {
    try {
      await apiService.deleteUser(deleteUserId);
      // Refresh the list of users after deletion
      fetchUsers(currentPage, itemsPerPage, statusValue, searchTitle);
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSaveEdit = async (updatedUser) => {
    try {
      const response = await apiService.updateUser(updatedUser);
      if (response && response.message === "User updated successfully.") {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.UserID === updatedUser.UserID ? updatedUser : user
          )
        );
        setEditingUserId(null);
      } else {
        console.error("Failed to update user:", response);
        alert("Failed to update user. Please try again.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("An error occurred while updating the user. Please try again.");
    }
  };

  const handleCancelEdit = () => {
    setEditingUserId(null);
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        />
      );
      items.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return items;
  };

  return (
    <>
      <div className="gn-container adminusers">
        <div className="gn-header">
          <Row className="margin-0">
            <Col xs={12} md={6} lg={6}>
              <Row className="margin-0">
                <Col xs={12} md={12} lg={12}>
                  <div className="w-100">
                    <input
                      value={searchTitle}
                      type="text"
                      placeholder="Search..."
                      className="form-control"
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Row className="margin-0">
                <Col xs={12} md={6} lg={6}>
                  <ButtonGroup>
                    {radios.map((radio, idx) => (
                      <ToggleButton
                        key={idx}
                        id={`radio-${idx}`}
                        type="radio"
                        variant={
                          radio.value === "ALL"
                            ? "outline-success"
                            : radio.value === "Admin"
                            ? "outline-secondary"
                            : radio.value === "User"
                            ? "outline-warning"
                            : "outline-danger"
                        }
                        name="radio"
                        value={radio.value}
                        checked={statusValue === radio.value}
                        onChange={(e) =>
                          handleRadioChange(e.currentTarget.value)
                        }
                      >
                        {radio.name}
                      </ToggleButton>
                    ))}
                  </ButtonGroup>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <Button variant="primary" onClick={handleAddUser}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="gn-body">
          <Row className="margin-0">
            {users?.length > 0 ? (
              users.map((user) => (
                <Col key={user.UserID} xs={12} md={6} lg={4} className="mb-4">
                  {editingUserId === user.UserID ? (
                    <EditUserForm
                      user={user}
                      onSave={handleSaveEdit}
                      onCancel={handleCancelEdit}
                    />
                  ) : (
                    <div className="gn-card">
                      <div
                        className={`user-card ${
                          user.Role === "admin" ? "admin" : "user"
                        }`}
                      >
                        <div className="user-avatar1">
                          {user.UserImage ? (
                            <img
                              src={user.UserImage}
                              alt="User Avatar"
                              className="user-avatar-img"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faUser} size="3x" />
                          )}
                        </div>
                        <div className="user-info">
                          <h3>{user.UserName}</h3>
                          <p>{user.UserEmail}</p>
                          <Button
                            variant="outline-info"
                            onClick={() => handleEditUser(user.UserID)}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            variant="outline-danger"
                            onClick={() => handleDeleteUser(user.UserID)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              ))
            ) : (
              <div>No users found</div>
            )}
          </Row>
        </div>
        <div className="gn-footer">
          <Row className="margin-0">
            <Col>
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="dropdown-basic"
                >
                  {itemsPerPage}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {[6, 12, 24].map((size) => (
                    <Dropdown.Item
                      key={size}
                      onClick={() => handleSelectItemsPerPage(size)}
                    >
                      {size}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="text-center">
              <Pagination className="justify-content-end">
                {renderPaginationItems()}
              </Pagination>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeletePost}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminUsers;
