import React, { useEffect } from "react";
import "./ContactUs.css"; // Import the CSS file
import { baseUrl } from "../../config";
import SEO from "../../common/SEO";
import { trackEvent } from "../../components/trackingUtils";
const ContactUs = () => {
  useEffect(() => {
    trackEvent("PageLoad", "", "ContactUs"); // Track the page load event
  }, []);
  return (
    <>
      <SEO
        title="Contact GeniusNexa - Reach Out for Tech Insights"
        description="Get in touch with GeniusNexa for the latest tech insights, tutorials, and reviews. Contact our Chief Executive Officer, Renukumari Senthil, for more information."
        keywords="contact GeniusNexa, tech support, tech insights, tech tutorials, tech reviews, industry insights, Renukumari Senthil"
        url={`${baseUrl}/contact`}
        image={`${baseUrl}/images/CEO.png`}
      />
      <div className="container">
        <h2 className="section-title">Contact Us</h2>
        <div className="contact-content">
          <div className="ceo-info">
            <img
              src={`${baseUrl}/images/CEO.png`}
              alt="Renukumari Senthil"
              className="ceo-photo"
            />
            <div className="ceo-details">
              <h3>Renukumari Senthil</h3>
              <p>Chief Executive Officer</p>
              <p>24 C3 Extension-1, Udayar Avenue </p>
              <p>Thuraiyur, TN, 621010</p>
              <p>Email: renukumari@geniusnexa.com</p>
              <p>Phone: +91 9944544603</p>
            </div>
          </div>
          <div className="map">
            <img
              src={`${baseUrl}/images/geniusnexa.png`}
              alt="GeniusNexa office location on Google Map"
              className="map-image"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
