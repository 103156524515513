import React, { useEffect, useState, useCallback } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import apiService from "../../ApiService"; // Import your apiService
import { Row, Col, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const AdminReports = () => {
  const [eventDetails, setEventDetails] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Function to format dates to 'YYYY-MM-DD'
  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Memoize the fetchEventData function to avoid recreating it on every render
  const fetchEventData = useCallback(async () => {
    try {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const response = await apiService.getEvents(
        formattedStartDate,
        formattedEndDate
      );

      if (response.status === "success") {
        setEventDetails(response.data); // Store the detailed event data for the table
      } else {
        console.error("Error fetching events:", response);
      }
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [startDate, endDate]); // Dependency array with startDate and endDate

  useEffect(() => {
    fetchEventData();
  }, [fetchEventData]); // Add fetchEventData to the dependency array

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the eventDetails based on the search query
  const filteredEvents = eventDetails.filter((item) =>
    Object.values(item).some((val) =>
      val.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  // Group data by PageUrl to count event occurrences for the graph
  const aggregatedData = filteredEvents.reduce((acc, item) => {
    const found = acc.find((data) => data.LinkText === item.LinkText);
    if (found) {
      found.Clicks += 1;
    } else {
      acc.push({
        LinkText: item.LinkText,

        Clicks: 1,
      });
    }
    return acc;
  }, []);

  return (
    <div className="gn-container adminusers">
      <div className="gn-header">
        <Row>
          <Col md={4} className="p-0">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                width: "100%",
                padding: "8px",
                marginBottom: "10px",
                fontSize: "16px",
              }}
            />
          </Col>
          <Col md={3}>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText="Start Date"
            />
          </Col>
          <Col md={3}>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              dateFormat="yyyy-MM-dd"
              className="form-control ms-2"
              placeholderText="End Date"
            />
          </Col>
          <Col md={1} className="d-flex align-items-center p-0">
            <Button
              variant="primary"
              onClick={fetchEventData}
              style={{ marginLeft: "10px", marginTop: "-11px" }}
            >
              Refresh
            </Button>
          </Col>
        </Row>
      </div>
      <div className="gn-body">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={aggregatedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="LinkText" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Clicks" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>

        <h3>Details Table</h3>

        <Row>
          <Col>
            <table
              border="1"
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr>
                  <th>EventType</th>
                  <th>PageUrl</th>
                  <th>UserIp</th>
                  <th>ReferrerUrl</th>
                  <th>ClickUrl</th>
                  <th>LinkText</th>
                  <th>CreatedAt</th>
                </tr>
              </thead>
              <tbody>
                {filteredEvents.length > 0 ? (
                  filteredEvents.map((item, index) => (
                    <tr key={index}>
                      <td>{item.EventType}</td>
                      <td>{item.PageUrl}</td>
                      <td>{item.UserIp}</td>
                      <td>{item.ReferrerUrl}</td>
                      <td>{item.ClickUrl}</td>
                      <td>{item.LinkText}</td>
                      <td>{item.CreatedAt}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">No events found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminReports;
