import React from "react";

const AdminSettings = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
      <p>Welcome to the admin dashboard.</p>
    </div>
  );
};

export default AdminSettings;
