import React from "react";
import { Button } from "react-bootstrap";
import { formatDateTime } from "../../utils/DateUtils";
import { baseUrl } from "../../config";
import "./PostView.css";

const PostView = ({ post, onBack }) => {
  return (
    <div className="postview">
      <div className="post-form">
        <div className="post-form-header d-flex justify-content-between align-items-center">
          <div>
            <h2 className="title">{post.Title}</h2>
          </div>
          <div>
            <Button variant="secondary" onClick={onBack}>
              Back
            </Button>
          </div>
        </div>
        <div className="post-form-body">
          {post && (
            <div className="blog-post">
              <div className="blog-header">
                <img
                  src={baseUrl + post.ImageUrl}
                  alt={post.Title}
                  className="blog-image"
                />
              </div>
              <div className="author-header">
                <p className="author-name margin-0">{post.Author}</p>
                <p className="published-date margin-0 ps-2">
                  {formatDateTime(post.ModifiedDate)}
                </p>
              </div>

              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: post.Content }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostView;
