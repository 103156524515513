import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";
import TopMenu from "./Pages/master/Header";
import Footer from "./Pages/master/Footer";
import Homepage from "./Pages/homepage/Homepage";
import Services from "./Pages/services/Services";
import Blog from "./Pages/blog/Blog";
import BlogsDetails from "./Pages/blog/BlogsDetails";
import CareerPage from "./Pages/careers/Careers";
import Breadcrumbs from "./components/Breadcrumbs";

import ServiceData from "./Data/ServiceData";
import ScrollToTop from "./common/ScrollToTop";

import AdminLayout from "./Pages/admin/AdminLayout";
import Login from "./Pages/login/Login";
import Register from "./Pages/login/Register";
import Logout from "./Pages/login/Logout";
import BlogPost from "./Pages/blog/BlogPost";
import NotFound from "./components/NotFound";
import apiService from "./ApiService";
import ServicesMaster from "./Pages/services/ServicesMaster";
import CEO from "./Pages/CEO/CEO";
import ContactUs from "./Pages/contactus/ContactUs";
import SeoMetaChecker from "./tools/SeoMetaChecker";
import ImageConverter from "./tools/ImageConverter";
import TextComparison from "./tools/TextComparison";
import { ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import Toast CSS
import PrivacyPolicy from "./Pages/google/PrivacyPolicy";
import Tools from "./tools/Tools";
import PDFViewer from "./components/PDFViewer";

const App = () => {
  const [, setUser] = useState(null);
  const [dynamicData, setDynamicData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [PdfViewer, setPdfViewer] = useState(null);
  const isSnap = navigator.userAgent.includes("ReactSnap");

  useEffect(() => {
    fetchDynamicData();
    fetchBlogs();

    if (!isSnap) {
      import("./smartclass/PdfViewer")
        .then((module) => setPdfViewer(() => module.default))
        .catch((err) => console.error("Error loading PdfViewer:", err));
    }
  }, [isSnap]);

  const fetchBlogs = async () => {
    try {
      const data = await apiService.fetchBlog(); // Call the fetchBlog method from apiService
      setBlogs(data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const fetchDynamicData = async () => {
    try {
      const response = await apiService.fetchUrl();
      setDynamicData(response);
      setLoading(false); // Set loading to false once data is fetched
    } catch (error) {
      console.error("Error fetching dynamic data:", error);
      setLoading(false); // Set loading to false on error as well
    }
  };

  const getDynamicPages = () => {
    if (!dynamicData || loading) return <div>Loading...</div>;

    const formattedPages = dynamicData.map((item) => ({
      Category: item.Category,
      SEOUrls: item.SEOUrls ? item.SEOUrls : [],
    }));

    return formattedPages
      .map((category, index) => (
        <Route
          key={`category-${index}`}
          exact
          path={`/blogs/${category.Category}`}
          component={() => <BlogPost SEOUrl={category.Category} />}
        />
      ))
      .concat(
        formattedPages.flatMap((category, index) =>
          category.SEOUrls.map((seoUrl, i) => (
            <Route
              key={`seo-${index}-${i}`}
              path={`/blogs/${category.Category}/${seoUrl}`}
              component={() => (
                <BlogsDetails Category={category.Category} SEOUrl={seoUrl} />
              )}
            />
          ))
        )
      );
  };

  return (
    <Router>
      <div className="App">
        <div className="fixed-header">
          <TopMenu blogs={blogs} />
        </div>
        <div className="main-content">
          <ScrollToTop />
          <Breadcrumbs />
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => (
                <Homepage {...props} services={ServiceData} blogs={blogs} />
              )}
            />
            <Route
              exact
              path="/services"
              render={(props) => (
                <ServicesMaster {...props} services={ServiceData} />
              )}
            />
            <Route
              path="/services/:url"
              render={(props) => <Services {...props} services={ServiceData} />}
            />
            <Route exact path="/blogs" component={Blog} />
            <Route path="/careers" component={CareerPage} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/ceo" component={CEO} />
            <Route
              path="/login"
              render={(props) => <Login {...props} setUser={setUser} />}
            />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/register" component={Register} />
            <Route
              path="/logout"
              render={(props) => <Logout {...props} setUser={setUser} />}
            />
            <Route
              exact
              path="/tools/seonavigator"
              component={SeoMetaChecker}
            />
            <Route
              exact
              path="/tools/imageconverter"
              component={ImageConverter}
            />
            <Route
              exact
              path="/tools/comparexpert"
              component={TextComparison}
            />
            <Route exact path="/tools" component={Tools} />
            {!isSnap && PdfViewer && (
              <Route
                exact
                path="/summit2new"
                render={(props) => <PdfViewer {...props} />}
              />
            )}
            <Route
              exact
              path="/summit2"
              render={(props) => (
                <PDFViewer pdfUrl="/uploads/Summit_2_book.pdf" />
              )}
            />

            {getDynamicPages()}
            <Route component={NotFound} />
          </Switch>
          <FooterContent />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
};

const FooterContent = () => {
  const location = useLocation();
  const [isHomepage, setIsHomepage] = useState(location.pathname === "/");
  useEffect(() => {
    setIsHomepage(location.pathname === "/");
  }, [location]);

  return <>{isHomepage && <Footer />}</>;
};

export default App;
