// src/components/Footer.js
import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  const handleEmailClick = () => {
    window.location.href = "mailto:support@geniusnexa.com";
  };
  return (
    <footer className="bg-dark text-white p-4 text-center">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <h5>About GeniusNexa</h5>
            <p>
              GeniusNexa is a leading software service company specializing in
              innovative digital solutions. We are committed to helping
              businesses thrive in the digital age with our expert services.
            </p>
          </div>
          <div className="col-md-4 ">
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/#services" className="text-white">
                  Services
                </a>
              </li>
              <li>
                <a href="/#about-us" className="text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/#contact-us" className="text-white">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 contactus">
            <h5>Contact Us</h5>
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
                <a
                  href="mailto:support@geniusnexa.com"
                  onClick={handleEmailClick}
                >
                  support@geniusnexa.com
                </a>
              </li>

              <li>
                <FontAwesomeIcon icon={faPhone} className="icon" />
                <span> +1 (562) 743-7986</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />{" "}
                <span>
                  24 C3 Extension-1, Udayar Avenue, Ongara Kudil Road,
                  Thuraiyur, TN 631010
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3">
          <p className="mb-0">
            &copy; {new Date().getFullYear()} GeniusNexa. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
