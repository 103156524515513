import React from "react";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { useMediaQuery } from "react-responsive";

const GNConfetti = () => {
  const { width, height } = useWindowSize();

  // Define breakpoints for media queries
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const isMediumScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const isLargeScreen = useMediaQuery({ query: "(min-width: 901px)" });

  // Adjust confetti width and height based on screen size
  let confettiWidth = width;
  let confettiHeight = height;

  if (isSmallScreen) {
    confettiWidth = width * 0.9; // Reduce width on small screens
    confettiHeight = height * 0.9; // Reduce height on small screens
  } else if (isMediumScreen) {
    confettiWidth = width * 0.95; // Slightly reduce width on medium screens
    confettiHeight = height * 0.95; // Slightly reduce height on medium screens
  } else if (isLargeScreen) {
    confettiWidth = width; // Full width on large screens
    confettiHeight = height; // Full height on large screens
  }

  // Prevent vertical scrollbar
  confettiHeight = Math.min(height, window.innerHeight);

  return (
    <Confetti
      width={confettiWidth - 50}
      height={confettiHeight}
      recycle={false} // Confetti will not regenerate after it finishes
      tweenDuration={500} // Duration of confetti animation
    />
  );
};

export default GNConfetti;
