import React, { useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  Form,
  Alert,
} from "react-bootstrap";
import { diffWordsWithSpace, diffJson } from "diff";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // Import Prism theme for syntax highlighting
import beautify from "json-beautify";
import "./TextComparison.css"; // Import custom CSS
import SEO from "../common/SEO"; // Import the SEO component
import { baseUrl } from "../config";
const TextComparison = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [diffHtml, setDiffHtml] = useState("");
  const [formattedJson, setFormattedJson] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isJsonComparison, setIsJsonComparison] = useState(false);

  const formatJson = (jsonString) => {
    try {
      const jsonObject = JSON.parse(jsonString);
      return beautify(jsonObject, null, 2, 80);
    } catch (e) {
      return null;
    }
  };

  const compareTexts = () => {
    setLoading(true);
    setError(""); // Ensure error state is cleared before starting the comparison
    setDiffHtml("");
    setFormattedJson("");

    try {
      if (isJsonComparison) {
        // Format JSON
        const formatted1 = formatJson(text1);
        const formatted2 = formatJson(text2);

        if (formatted1 === null || formatted2 === null) {
          setError("Invalid JSON format. Please check your input.");
          return;
        }

        // Parse JSON for comparison
        const obj1 = JSON.parse(formatted1);
        const obj2 = JSON.parse(formatted2);

        // Compare JSON
        const diff = diffJson(obj1, obj2);

        // Generate HTML for JSON differences
        const html = diff
          .map((part) => {
            if (part.added)
              return `<span class="json-added">${part.value}</span>`;
            if (part.removed)
              return `<span class="json-removed">${part.value}</span>`;
            return part.value;
          })
          .join("");

        setDiffHtml(html);

        // Set formatted JSON results
        setFormattedJson(`
          <h5>Formatted JSON 1:</h5>
          <pre class="json-result">${Prism.highlight(
            formatted1,
            Prism.languages.json,
            "json"
          )}</pre>
          <h5>Formatted JSON 2:</h5>
          <pre class="json-result">${Prism.highlight(
            formatted2,
            Prism.languages.json,
            "json"
          )}</pre>
        `);
      } else {
        // Compare plain text
        const diff = diffWordsWithSpace(text1, text2);

        // Generate HTML for text differences
        const html = diff
          .map((part) => {
            if (part.added) return `<span class="added">${part.value}</span>`;
            if (part.removed)
              return `<span class="removed">${part.value}</span>`;
            return part.value;
          })
          .join("");

        setDiffHtml(html);
      }
    } catch (err) {
      //console.error("Error comparing texts:", err);
      //setError("An error occurred while comparing texts.");
    } finally {
      setLoading(false);
    }
  };

  const clearText = () => {
    setText1("");
    setText2("");
    setDiffHtml("");
    setFormattedJson("");
    setError("");
  };

  return (
    <>
      <SEO
        title="CompareXpert - Ultimate Tool for Text, JSON, PDF, and Image Comparisons"
        description={`Unlock the power of CompareXpert, your all-in-one tool for comparing text, JSON, PDFs, and images. Effortlessly identify differences, optimize content, and streamline your comparison tasks. Start using CompareXpert today for precise and efficient comparisons.`}
        keywords="CompareXpert, text comparison tool, JSON comparison, PDF comparison, image comparison, comparison tool, diff analyzer, content optimization"
        url={baseUrl + "/tools/comparexpert"}
        image={baseUrl + "/images/comparexpert.png"}
      />

      <Container>
        <Row className="mb-3">
          <Col>
            <Button
              variant={!isJsonComparison ? "primary" : "outline-primary"}
              onClick={() => setIsJsonComparison(false)}
              className="me-2"
            >
              Compare as Text
            </Button>
            <Button
              variant={isJsonComparison ? "primary" : "outline-primary"}
              onClick={() => setIsJsonComparison(true)}
              className="me-2"
            >
              Compare as JSON
            </Button>
            {/* Add Clear Button */}
            <Button
              variant="outline-danger"
              onClick={clearText}
              className="float-end"
            >
              Clear
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card className="mb-3 shadow-lg">
              <Card.Header>Original text</Card.Header>
              <Card.Body>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={text1}
                  onChange={(e) => setText1(e.target.value)}
                  placeholder="Enter the first text here..."
                  className="text-area"
                />
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="mb-3 shadow-lg">
              <Card.Header>Changed text</Card.Header>
              <Card.Body>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={text2}
                  onChange={(e) => setText2(e.target.value)}
                  placeholder="Enter the second text here..."
                  className="text-area"
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Button
          className="mb-4 w-100"
          onClick={compareTexts}
          variant="primary"
          disabled={loading || !text1 || !text2}
        >
          {loading ? "Comparing..." : "Compare"}
        </Button>
        {/* Only show the error if there is an actual error and no formatted JSON */}
        {error && !formattedJson && <Alert variant="danger">{error}</Alert>}
        {(diffHtml || formattedJson) && (
          <Card className="mt-3 shadow-lg">
            <Card.Header>Comparison Result</Card.Header>
            <Card.Body>
              <div
                className="diff-result"
                dangerouslySetInnerHTML={{ __html: diffHtml + formattedJson }}
              />
            </Card.Body>
          </Card>
        )}
      </Container>
    </>
  );
};

export default TextComparison;
