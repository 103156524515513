import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { baseUrl } from "../config";

// Helper function to extract filename and size
const extractFilenameAndSize = (url) => {
  const filename = url.substring(url.lastIndexOf("/") + 1);
  const sizeMatch = filename.match(/_(\d+x\d+)_/);
  const size = sizeMatch ? sizeMatch[1] : null;
  return { filename, size };
};

// Function to extract size from Windows icon filename
const extractSizeFromFilename = (filename) => {
  const pattern = /windows-phone-tile_(large|medium|small|wide)_(.+)/;
  const match = filename.match(pattern);

  const sizeMapping = {
    small: "70x70",
    medium: "150x150",
    wide: "310x150",
    large: "310x310",
  };

  return match ? { size: sizeMapping[match[1]] || null } : { size: null };
};

const SEO = ({
  title,
  description,
  keywords,
  image,
  url,
  facebook,
  twitter,
  whatsapp,
  linkedin,
  teams,
  type = "website",
  androidicons = `${baseUrl}/icons/icon-192x192.png,${baseUrl}/icons/icon-512x512.png`,
  appletouchicon = `${baseUrl}/icons/apple-touch-icon_57x57_logo.png,${baseUrl}/icons/apple-touch-icon_60x60_logo.png,${baseUrl}/icons/apple-touch-icon_72x72_logo.png,${baseUrl}/icons/apple-touch-icon_76x76_logo.png,${baseUrl}/icons/apple-touch-icon_114x114_logo.png,${baseUrl}/icons/apple-touch-icon_120x120_logo.png,${baseUrl}/icons/apple-touch-icon_144x144_logo.png,${baseUrl}/icons/apple-touch-icon_152x152_logo.png,${baseUrl}/icons/apple-touch-icon_180x180_logo.png,${baseUrl}/icons/apple-touch-icon_192x192_logo.png,${baseUrl}/icons/apple-touch-icon_512x512_logo.png`,
  windowsicon = `${baseUrl}/icons/windows-phone-tile_large_logo.png,${baseUrl}/icons/windows-phone-tile_medium_logo.png,${baseUrl}/icons/windows-phone-tile_small_logo.png,${baseUrl}/icons/windows-phone-tile_wide_logo.png`,
}) => {
  const appleIcons = appletouchicon.split(",").map((icon) => icon.trim());
  const androidIcons = androidicons.split(",").map((icon) => icon.trim());
  const windowsIcons = windowsicon.split(",").map((icon) => icon.trim());

  return (
    <HelmetProvider>
      <Helmet>
        {/* General Meta Tags */}
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        {keywords && <meta name="keywords" content={keywords} />}

        {/* OpenGraph Meta Tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content={type} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content="GeniusNexa" />
        <meta property="og:url" content={url} />
        {(facebook || image) && (
          <meta property="og:image" content={facebook || image} />
        )}

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        {(twitter || image) && (
          <meta name="twitter:image" content={twitter || image} />
        )}

        {/* WhatsApp Meta Tags */}
        <meta property="whatsapp:title" content={title} />
        <meta property="whatsapp:description" content={description} />
        {(whatsapp || image) && (
          <meta property="whatsapp:image" content={whatsapp || image} />
        )}

        {/* LinkedIn Meta Tags */}
        <meta property="linkedin:title" content={title} />
        <meta property="linkedin:description" content={description} />
        {(linkedin || image) && (
          <meta property="linkedin:image" content={linkedin || image} />
        )}

        {/* Microsoft Teams Meta Tags */}
        <meta property="teams:title" content={title} />
        <meta property="teams:description" content={description} />
        {(teams || image) && (
          <meta property="teams:image" content={teams || image} />
        )}

        {/* Apple Touch Icons */}
        {appleIcons.map((icon, index) => {
          const { size } = extractFilenameAndSize(icon);
          return size ? (
            <link key={index} rel="apple-touch-icon" sizes={size} href={icon} />
          ) : null;
        })}

        <meta name="msapplication-TileColor" content="#000000" />

        {/* Windows Icons */}
        {windowsIcons.map((icon, index) => {
          const { size } = extractSizeFromFilename(icon);
          return size ? (
            <meta
              key={index}
              name={`msapplication-square${size}logo`}
              content={icon}
            />
          ) : null;
        })}

        {/* Android Icons */}
        {androidIcons.map((icon, index) => {
          const size = icon.match(/(\d+x\d+)/)?.[0];
          return size ? (
            <link key={index} rel="icon" sizes={size} href={icon} />
          ) : null;
        })}
      </Helmet>
    </HelmetProvider>
  );
};

export default SEO;
