import React from "react";
import "./PrivacyPolicy.css"; // Optional: Add a CSS file for styling
import { Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Last updated on July 27, 2024</p>
        <p>
          GeniusNexa, hereinafter referred to as ‘GeniusNexa’, ‘we’, ‘us’ or
          ‘our’, is committed to respecting your privacy and choices. This
          privacy policy (hereinafter “Privacy Policy” or “Policy”) highlights
          our privacy practices regarding Personal Information that we collect
          and process in compliance with applicable data privacy regulations.
        </p>

        <h2>Objective</h2>
        <p>
          Through this privacy policy, we intend to provide a synopsis of our
          privacy practices regarding Personal Information that we collect and
          process about you through various sources. The primary focus of this
          policy is on the data collected and processed through our websites,
          that link to this Privacy Policy, which include www.geniusnexa.com and
          its sub-domains.
        </p>
        <p>
          The scope of this Policy covers the categories of personal data
          collected, how we use or process such data, who are the recipients of
          such data, and your associated rights under applicable laws including
          how to exercise the same. The applicability of the Privacy Policy is
          global; however, certain additional information may be relevant to you
          depending on the country where you reside. Such additional terms,
          based on these particular countries or regions, are called out in
          region-specific statements below.
        </p>
        <p>
          Wherever we may have provided the translation of our Policy in local
          languages, as per applicability, in case of a conflict between the
          local language and the English version, the English version shall
          prevail.
        </p>

        <h2>1. Personal Information that we process and use</h2>
        <h3>1.1 Personal Information that we process</h3>
        <p>We may collect the following categories of Personal Information:</p>
        <ul>
          <li>
            Information collected as part of your visit and usage of our
            websites, such as Internet Protocol (IP) address, demographics, your
            computers’ operating system, device information, telemetry data, and
            browser type and information collected via cookies or similar
            tracking technologies.
          </li>
          <li>
            Personal and Contact details, such as Name, title, e-mail address,
            phone numbers, address, designation, Company/Organization, Industry,
            Location (City/Country), LinkedIn profiles/URLs (or similar
            professional data set).
          </li>
          <li>
            Login credentials, such as Username and Passwords, when you register
            for any of our applicable services.
          </li>
          <li>
            Audio-Visual information such as photographs or images captured,
            video recordings (if enabled), when attending any of our events,
            webinars, etc.
          </li>
          <li>
            Queries, comments, or feedback as submitted by you, including any
            correspondence you have made with us.
          </li>
          <li>
            Preferences relating to marketing communications, interest areas,
            subscription details, dietary preferences/allergies (in relation to
            events we may organize).
          </li>
          <li>
            Additionally, we may collect certain data points based on the
            categories of data subject and their relationship with us, as
            detailed out in additional Privacy Statements for relevant
            categories of data subjects at the end of this Policy.
          </li>
        </ul>

        <h3>1.2 Sources of Personal Information</h3>
        <p>
          We may collect the aforementioned Personal Information through various
          sources, such as mentioned below:
        </p>
        <ul>
          <li>
            Submitted by yourself, through our Website forms, applications on
            our portals, or by contacting/emailing our official contacts.
          </li>
          <li>
            Shared to GeniusNexa employees, such as our sales or marketing
            representatives.
          </li>
          <li>Shared with or by GeniusNexa’s affiliates.</li>
          <li>
            Shared by the employers of the visitors and contractors, where
            applicable.
          </li>
          <li>
            Sourced from public websites and social media, including your
            publicly accessible profiles.
          </li>
          <li>Shared by our suppliers, vendors, and service providers.</li>
          <li>
            Sourced via Cookies and similar tracking technologies as deployed on
            our website (details are available in the Cookie Policy).
          </li>
        </ul>

        <h3>1.3 Use of your Personal Information</h3>
        <p>We may use your Personal Information for the following purposes:</p>
        <ul>
          <li>
            to provide better usability, troubleshooting, and site maintenance
          </li>
          <li>
            to understand which parts of the website are visited and how
            frequently
          </li>
          <li>to create your login credentials, where applicable</li>
          <li>to identify you once you register on our website</li>
          <li>
            to facilitate communication with you, including contacting you and
            responding to your queries or requests
          </li>
          <li>to offer curated content tailored to your preferences</li>
          <li>
            to enable marketing and sales-related communications and related
            purposes
          </li>
          <li>to provide access to podcasts available on our website(s)</li>
          <li>
            to know your interest in participation in ideathons or other
            competitions
          </li>
          <li>
            as a participant of any competitions and/or award ceremonies
            organized by us, to publish your name and corresponding details to
            make them available to a larger audience on the Internet (including
            details of awards won); for the jury members and other associated
            delegates and speakers, to publish their name, title, photograph,
            and bio in a similar manner
          </li>
          <li>
            to invite you for events, seminars, and equivalent ceremonies
            organized by us and related purposes such as running marketing or
            promotional campaigns, including such promotions or publications on
            social media
          </li>
          <li>to publish testimonials and case studies</li>
          <li>
            to generate and maintain leads as part of our Customer Relationship
            Management database
          </li>
          <li>
            to perform data analytics for providing a better user experience,
            enhance website performance and achieve business objectives
          </li>
          <li>
            to enhance and optimize our business operations, applications,
            systems, and processes, including operation and management of our
            communication assets and systems, ensuring and strengthening
            information security, maintenance of audit trail and associated
            records
          </li>
          <li>
            to protect your data and GeniusNexa assets from information security
            threats and to secure against any unauthorized access, disclosure,
            alteration, or destruction
          </li>
          <li>
            to comply with applicable laws and other legal requirements, such as
            regulatory filings, record-keeping, and reporting obligations,
            cooperating with government authorized inspections or requests,
            protecting our legal rights, and seeking remedies, including
            defending against any claims or engaging in legal proceedings,
            responding to subpoenas or court orders, and managing documentation
            for related purposes
          </li>
          <li>
            to enhance your website experience and other associated purposes by
            deploying cookies or similar technologies. These may serve different
            purposes. For example, some of the cookies may be necessary for the
            operation of the website, while others are used to ensure its
            seamless performance. They may also enhance the functionalities
            provided on our website, help us understand how our online services
            are used, or to determine the interests of our website visitors.
            Additionally, our advertising partners collaborate with us to
            provide and assist in the use of such technologies on GeniusNexa and
            other sites. For more information, refer to the cookie policies of
            our respective domains as called out below.
          </li>
        </ul>
        <p>
          For GeniusNexa.com and associated sub-domains, please refer to this
          cookie policy.
        </p>
        <p>
          For some of the personal data processing we undertake, we might use
          AI, Machine learning and related technologies to enhance customer
          experience and data analytic capabilities, but whenever we do so, we
          use responsible AI practices.
        </p>

        <h3>1.4 Legal basis of the processing</h3>
        <p>
          The applicable privacy and data protection laws provide for certain
          justifiable grounds for processing Personal Information, including:
        </p>
        <ul>
          <li>
            Consent: In some cases, we may process your Personal Information
            based on your consent. This will usually be the case where we need
            to process special categories of Personal Information, such as
            health data, or where required by applicable laws.
          </li>
          <li>
            Contractual necessity: In other cases, we may need to process your
            Personal Information to enter into or perform a contract with you.
          </li>
          <li>
            Compliance with legal obligations: We may also process your Personal
            Information to comply with our legal obligations.
          </li>
          <li>
            Legitimate interests: We may process your Personal Information based
            on our legitimate interests in communicating with you, managing our
            relationship with you, and conducting our business.
          </li>
        </ul>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data processing practices, please contact us at
          support@geniusnexa.com.
        </p>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
