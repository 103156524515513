import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Category.css";
import { baseUrl } from "../../config";

const Category = ({ blogs }) => {
  const [showAll] = useState(false);
  const history = useHistory(); // Hook to programmatically navigate using history

  // Determine how many blogs to show based on the showAll state
  const visibleBlogs = showAll ? blogs : blogs.slice(0, 4);

  // Function to handle button click
  const handleViewAll = () => {
    history.push("/blogs/"); // Navigate to /blogs/ page
  };

  return (
    <div className="container" id="blogs">
      <h2 className="section-title">Blogs</h2>
      <div className="categories-container">
        {visibleBlogs && visibleBlogs.length > 0 ? (
          visibleBlogs.map((blog, idx) => (
            <Link
              key={idx}
              to={`/blogs/${blog.SEOUrl}`}
              className="service-link"
            >
              <div className="category-card">
                <div className="image-container">
                  <img
                    src={baseUrl + "/" + blog.ImageUrl}
                    alt={blog.Title}
                    className="card-image"
                  />
                  <div className="title-overlay">
                    <h3>{blog.Title}</h3>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div>No blogs available</div>
        )}
      </div>

      {blogs.length > 4 && (
        <button className="view-all-button" onClick={handleViewAll}>
          View All Blogs
        </button>
      )}
    </div>
  );
};

export default Category;
