import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Pagination,
  Spinner,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import "./Posts.css";
import PostForm from "./PostForm";
import apiService from "../../ApiService";
import { formatDateTime } from "../../utils/DateUtils";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

const AdminPosts = ({ user }) => {
  const [statusValue, setStatusValue] = useState("1");
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [posts, setPosts] = useState([]);
  const [showEditor, setShowEditor] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [editPost, setEditPost] = useState({
    PostID: null,
    BlogID: null,
    Title: "",
    Content: "",
    SEOUrl: "",
    MetaDescription: "",
    MetaKeywords: "",
    ImageUrl: null,
    TwitterImageUrl: null,
    FacebookImageUrl: null,
    WhatsappImageUrl: null,
    LinkedinImageUrl: null,
    TeamsImageUrl: null,
    WindowsIcon: null,
    AppleTouchIcon: null,
    AndroidIcon: null,
    PhotoCredit: null,
    Status: 0,
  });
  const radios = [
    { name: "Live", value: "1" },
    { name: "Review", value: "2" },
    { name: "Rejected", value: "3" },
    { name: "Draft", value: "0" },
  ];
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedBlogTitle, setSelectedBlogTitle] = useState("Filter Items");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false); // State for loading page change

  const fetchBlogPostsByCategory = useCallback(
    async (category, status, page, pagesize, searchTitle) => {
      try {
        const pageSizeToUse = pagesize === undefined ? itemsPerPage : pagesize;
        setLoadingPage(true); // Start loading page change
        const response = await apiService.fetchBlogPostByCategory(
          category,
          page,
          pageSizeToUse,
          searchTitle,
          status
        );
        if (response.length === 0) {
          setPosts([]);
          setError("No posts found.");
        } else {
          setPosts(response.posts);
          setTotalPages(response.totalPages); // Assuming the API returns the total number of pages
          setError(null);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setPosts([]);
          setError("No items found.");
        } else {
          setError(`${error.message}`);
          setPosts([]);
        }
      } finally {
        setLoadingPage(false); // Stop loading page change
      }
    },
    [itemsPerPage]
  );

  useEffect(() => {
    const loadBlogs = async () => {
      try {
        const data = await apiService.fetchBlog();
        setBlogs(data);
        if (data && data.length > 0) {
          const firstBlogId = data[0].BlogID;
          setSelectedBlog(firstBlogId);
          setSelectedBlogTitle(data[0].Title);
          fetchBlogPostsByCategory(data[0].SEOUrl, statusValue, 1);
        }
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    loadBlogs();
  }, [fetchBlogPostsByCategory, statusValue]);

  const handleAddPost = async () => {
    try {
      setEditPost({
        PostID: null,
        BlogID: null,
        Title: "",
        Content: "",
        SEOUrl: "",
        MetaDescription: "",
        MetaKeywords: "",
        ImageUrl: null,
        TwitterImageUrl: null,
        FacebookImageUrl: null,
        WhatsappImageUrl: null,
        TeamsImageUrl: null,
        LinkedinImageUrl: null,
        WindowsIcon: null,
        AppleTouchIcon: null,
        AndroidIcon: null,
        PhotoCredit: null,
        Status: 0,
      });
      setStatusValue("0");
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, 0, currentPage);
      setShowEditor(true);
    } catch (error) {
      setError(`Error Adding post: ${error.message}`);
    }
  };

  const handleEditPost = (post) => {
    setEditPost(post);
    setShowEditor(true);
  };

  const handleDeletePost = async (PostID) => {
    setPostToDelete(PostID, statusValue);
    setShowDeleteModal(true);
  };
  const handleCancelDelete = () => {
    setPostToDelete(null);
    setShowDeleteModal(false);
  };
  const confirmDeletePost = async () => {
    try {
      await apiService.deleteBlogPost(postToDelete, statusValue);
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, statusValue, currentPage);
    } catch (error) {
      setError(`Error deleting post: ${error.message}`);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleSelectItemsPerPage = async (eventKey) => {
    const svalue = parseInt(eventKey, 10);
    setItemsPerPage(svalue);
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(
        blogfind.SEOUrl,
        statusValue,
        currentPage,
        svalue
      ); // Fetch with current page
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSavePost = async (formData) => {
    setShowEditor(false);
    setEditPost({
      PostID: null,
      Title: "",
      Content: "",
      SEOUrl: "",
      MetaDescription: "",
      MetaKeywords: "",
      ImageUrl: null,
      TwitterImageUrl: null,
      FacebookImageUrl: null,
      WhatsappImageUrl: null,
      LinkedinImageUrl: null,
      TeamsImageUrl: null,
      WindowsIcon: null,
      AppleTouchIcon: null,
      AndroidIcon: null,
      PhotoCredit: null,
      BlogID: null,
      Status: 0,
    });
    try {
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, statusValue, currentPage);
    } catch (error) {
      setError(`Error reloading posts: ${error.message}`);
    }
  };

  const handleSelectBlog = async (blogId, event) => {
    blogId = parseInt(blogId); // Convert blogId to an integer
    setSelectedBlog(blogId);
    const selectedBlog = blogs.find((blog) => blog.BlogID === blogId);
    setSelectedBlogTitle(selectedBlog ? selectedBlog.Title : "Filter Items");

    try {
      await fetchBlogPostsByCategory(selectedBlog.SEOUrl, statusValue, 1); // Reset to page 1
      setCurrentPage(1);
    } catch (error) {
      // Handle error, if needed
    }
  };
  const handleSearchInputChange = (event) => {
    setSearchTitle(event.target.value); // Update searchTitle state
    // Call API with searchTitle
    const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
    fetchBlogPostsByCategory(
      blogfind.SEOUrl,
      statusValue,
      currentPage,
      itemsPerPage,
      event.target.value
    );
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    try {
      setLoadingPage(true); // Start loading page change
      const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
      await fetchBlogPostsByCategory(blogfind.SEOUrl, statusValue, page);
    } catch (error) {
      setError(`Error fetching blog posts: ${error.message}`);
    } finally {
      setLoadingPage(false); // Stop loading page change
    }
  };

  const handleRadioChange = async (value) => {
    setStatusValue(value);
    const blogfind = blogs.find((blog) => blog.BlogID === selectedBlog);
    if (blogfind) {
      await fetchBlogPostsByCategory(blogfind.SEOUrl, value);
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (currentPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
      items.push(
        <Pagination.Prev
          key="prev"
          onClick={() => handlePageChange(currentPage - 1)}
        />
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next
          key="next"
          onClick={() => handlePageChange(currentPage + 1)}
        />
      );
      items.push(
        <Pagination.Last
          key="last"
          onClick={() => handlePageChange(totalPages)}
        />
      );
    }

    return items;
  };

  return (
    <>
      {!showEditor && (
        <div className="gn-container adminpost">
          <div className="gn-header">
            <Row className="margin-0">
              <Col xs={12} md={6} lg={6}>
                <Row className="margin-0">
                  <Col xs={12} md={6} lg={6}>
                    <div className="me-3 w-100">
                      <input
                        value={searchTitle}
                        type="text"
                        placeholder="Search..."
                        className="form-control"
                        onChange={handleSearchInputChange}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Dropdown onSelect={handleSelectBlog}>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        className="w-100"
                      >
                        {selectedBlogTitle}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {loading ? (
                          <Dropdown.Item disabled>Loading...</Dropdown.Item>
                        ) : (
                          blogs.map((blog) => (
                            <Dropdown.Item
                              key={blog.BlogID}
                              eventKey={blog.BlogID}
                            >
                              {blog.Title}
                            </Dropdown.Item>
                          ))
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>

              <Col xs={12} md={6} lg={6}>
                <Row className="margin-0">
                  <Col xs={12} md={6} lg={6}>
                    <ButtonGroup>
                      {radios.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          variant={
                            radio.value === "0"
                              ? "outline-warning"
                              : radio.value === "1"
                              ? "outline-success"
                              : radio.value === "2"
                              ? "outline-secondary"
                              : "outline-danger"
                          }
                          name="radio"
                          value={radio.value}
                          checked={statusValue === radio.value}
                          onChange={(e) => {
                            setStatusValue(e.currentTarget.value);
                            handleRadioChange(e.currentTarget.value);
                          }}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                  <Col xs={12} md={6} lg={6}>
                    <Button variant="primary" onClick={handleAddPost}>
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      Add
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="gn-body">
            {loadingPage ? (
              <div className="text-center mt-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Row className="margin-0">
                {posts && posts.length > 0 ? (
                  posts.map((post) => (
                    <Col
                      key={post.PostID}
                      xs={12}
                      md={6}
                      lg={4}
                      className="mb-4"
                    >
                      <div
                        className={`blog-post-admin ${
                          post.Status === 0 ? "not-approved" : ""
                        }`}
                      >
                        <h3>{post.Title}</h3>
                        <div className="blog-post-content-admin">
                          <div
                            dangerouslySetInnerHTML={{ __html: post.Content }}
                          />
                        </div>
                        <div className="post-meta">
                          <p className="author">{post.Author}</p>
                          <p className="published-date">
                            {formatDateTime(post.ModifiedDate)}
                          </p>
                        </div>
                        <div className="btn-group">
                          <Button
                            variant="primary"
                            onClick={() => handleEditPost(post)}
                          >
                            <FontAwesomeIcon icon={faEdit} className="me-2" />
                          </Button>
                          <Button
                            variant="danger"
                            onClick={() => handleDeletePost(post.PostID)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="me-2"
                            />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  ))
                ) : error ? (
                  <Col xs={12}>
                    <p className="text-danger">{error}</p>
                  </Col>
                ) : (
                  <Col xs={12}>
                    <p>No posts available.</p>
                  </Col>
                )}
              </Row>
            )}
          </div>
          <div className="gn-footer">
            <Row className="margin-0">
              <Col xs={6}>
                <Dropdown onSelect={handleSelectItemsPerPage}>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {itemsPerPage}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item eventKey="6">6</Dropdown.Item>
                    <Dropdown.Item eventKey="9">9</Dropdown.Item>
                    <Dropdown.Item eventKey="12">12</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <Pagination>{renderPaginationItems()}</Pagination>
              </Col>
            </Row>
          </div>
        </div>
      )}
      {showEditor && (
        <Row className="mb-3 margin-0">
          <Col xs={12} className="padding-0">
            <div className="post-form-container">
              <PostForm
                post={editPost}
                onSave={handleSavePost}
                onCancel={() => setShowEditor(false)}
                BlogID={selectedBlog}
                UserID={user?.userId}
                Status={statusValue}
                blogsData={blogs}
              />
            </div>
          </Col>
        </Row>
      )}
      <Modal show={showDeleteModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this post?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeletePost}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminPosts;
