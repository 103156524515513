import React, { useState, useEffect } from "react";
import ImageUpload from "./ImageUpload";
import SocialMediaFormats from "./SocialMediaFormats";
import { Spinner } from "react-bootstrap";
import "./ImageConverter.css";
import SEO from "../common/SEO";
import { baseUrl } from "../config";
import { trackEvent } from "../components/trackingUtils";
const MainComponent = () => {
  const [socialMediaPaths, setSocialMediaPaths] = useState({
    twitter: null,
    facebook: null,
    whatsapp: null,
    linkedin: null,
  });
  useEffect(() => {
    trackEvent("PageLoad", "", "ImageConverter");
  }, []);
  const [uploadError, setUploadError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (imageUrl, paths, error) => {
    trackEvent("Click", paths);
    if (error) {
      setUploadError(true);
    } else {
      setSocialMediaPaths(paths);
      setUploadError(false);
    }
    setLoading(false);
  };

  const handleUploadStart = () => {
    setLoading(true);
  };

  return (
    <>
      <SEO
        title="Free Online Image Converter | Convert Images to Social Media Formats"
        description={`Easily convert your images into various social media formats with our free online image converter tool. Upload your image, customize formats for platforms like Twitter, Facebook, LinkedIn, and WhatsApp, and download your optimized images quickly and effortlessly. Try it now and enhance your social media presence!`}
        keywords="Image Converter,Online Image Converter,Convert Images to Social Media Formats,Image Upload and Conversion Tool,Free Image Format Converter,Social Media Image Resizer,Image Converter Tool Online"
        url={baseUrl + "/tools/imageconverter"}
        image={baseUrl + "/images/ImageConverter.png"}
      />
      <div className="container">
        <div className="upload-container">
          <ImageUpload
            onImageUpload={(imageUrl, paths, error) =>
              handleImageUpload(imageUrl, paths, error)
            }
            onUploadStart={handleUploadStart}
          />
          {loading && (
            <div className="spinner-container">
              <Spinner animation="border" />
            </div>
          )}
          {!uploadError &&
            Object.values(socialMediaPaths).some((path) => path !== null) && (
              <SocialMediaFormats socialMediaPaths={socialMediaPaths} />
            )}
        </div>
      </div>
    </>
  );
};

export default MainComponent;
