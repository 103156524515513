// trackingUtils.js
import apiService from "../ApiService";

export const trackEvent = async (eventType, clickUrl = "", linkText = "") => {
  const userAgent = navigator.userAgent;
  const currentUrl = window.location.href;
  // Skip tracking if the UserAgent contains 'ReactSnap'
  if (
    userAgent.includes("ReactSnap") ||
    currentUrl.includes("http://localhost")
  ) {
    return;
  }

  const eventData = {
    EventType: eventType,
    PageUrl: window.location.href,
    UserIp: "", // Typically, this would be retrieved from a backend or service
    ReferrerUrl: document.referrer,
    ClickUrl: clickUrl,
    LinkText: linkText, // Add link text to event data
    UserAgent: userAgent,
  };

  try {
    const response = await apiService.postEventTracking(eventData);
    console.log(`Event tracking response for ${eventType}:`, response);
  } catch (error) {
    console.error(`Error posting ${eventType} tracking data:`, error);
  }
};
