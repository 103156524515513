import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import apiService from "../../ApiService";

const Logout = () => {
  const [cookies, , removeCookie] = useCookies(["sessionId"]);
  const history = useHistory();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        const sessionId = cookies.sessionId;
        if (!sessionId) {
          history.push("/login");
          return;
        }

        const response = await apiService.logout(sessionId);

        if (response.success) {
          removeCookie("sessionId", { path: "/" });
          history.push("/login");
        } else {
          console.error("Logout failed:", response.message);
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    };

    logoutUser();
  }, [cookies, history, removeCookie]);

  return <div>Logging out...</div>;
};

export default Logout;
