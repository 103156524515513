import React, { useContext } from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import UserContext from "../contexts/UserContext";
import apiService from "../ApiService"; // Adjust the import path as necessary

const GoogleLoginButton = () => {
  const { login } = useContext(UserContext);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      // Check that credentialResponse and credentialResponse.credential are defined
      if (credentialResponse && credentialResponse.credential) {
        const result = await apiService.socialLogin(
          "loginWithGoogle",
          credentialResponse.credential
        );
        if (result.success) {
          console.log(result.user);
          login(result.user);
          // Redirect or perform other actions as necessary
        } else {
          console.error("Social login failed");
        }
      } else {
        console.error("Credential response is missing or invalid");
      }
    } catch (error) {
      console.error("Error with social login:", error);
    }
  };

  const handleGoogleLoginError = (error) => {
    console.error("Google login failed:", error);
  };

  return (
    <GoogleOAuthProvider clientId="32704192994-td13h1loar1tdfr1753rnsfrdr7af6no.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleGoogleLoginSuccess}
        onError={handleGoogleLoginError}
        useOneTap
      />
    </GoogleOAuthProvider>
  );
};

export default GoogleLoginButton;
