// NotFound.js

import React from "react";
import SEO from "../common/SEO"; // Import your SEO component
import { Link } from "react-router-dom"; // Import Link for navigation
import { FaHome } from "react-icons/fa"; // Import a home icon from react-icons
import "./NotFound.css"; // Import a CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <SEO
        title="404 - Page Not Found"
        description="Sorry, the page you are looking for does not exist. Check the URL or return to the homepage."
        keywords="404, page not found, error, GeniusNexa"
        url={window.location.href} // Dynamic URL for better SEO
      />
      <div className="not-found-content">
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
        <p>
          <Link to="/" className="home-link">
            <FaHome className="home-icon" /> Return to Homepage
          </Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
