import React, { useEffect } from "react";
import { trackEvent } from "./trackingUtils";
const PDFViewer = ({ pdfUrl }) => {
  console.log(pdfUrl);
  useEffect(() => {
    trackEvent("PageLoad", "", "Summit2"); // Track the page load event
  }, []);
  return (
    <>
      <div style={{ height: "86vh", width: "100%" }}>
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </div>
    </>
  );
};

export default PDFViewer;
