import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./ServicesMaster.css"; // Import CSS file for styling
import { baseUrl } from "../../config";
import SEO from "../../common/SEO";
import { trackEvent } from "../../components/trackingUtils";
const ServicesMaster = ({ services }) => {
  useEffect(() => {
    trackEvent("PageLoad", "", "ServicesMaster"); // Track the page load event
  }, []);
  return (
    <>
      <SEO
        title="GeniusNexa - Our Services"
        description="Explore the range of services offered by GeniusNexa. From technology solutions to consulting, discover how we can help you achieve your goals."
        keywords="Cloud services, Cloud infrastructure enhancement, .NET development, Python development, PDF conversion, PDF document management, Data scraping services, Data extraction, Data management, Data organization, Website building, Responsive website design, Custom website development, Web development services"
        image={baseUrl + `/images/ourservices.png`}
        url={baseUrl + `/services`}
      />
      <div className="services-list  container">
        <h1>Our Services</h1>
        <ul>
          {services.map((service, index) => (
            <li key={index}>
              <Link to={`/services/${service.url}`}>
                <h3>{service.title}</h3>
                <p>{service.description}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ServicesMaster;
