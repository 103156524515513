// CEOPage.js

import React from "react";
import "./CEO.css";
import { baseUrl } from "../../config";

const CEOPage = () => {
  return (
    <div className="ceo-container">
      <div className="section-title">
        <h1>Meet Our CEO</h1>
      </div>
      <div className="ceo-content">
        <div className="ceo-image">
          <img src={baseUrl + "/images/logo.png"} alt="CEO" />
        </div>
        <div className="ceo-details">
          <h2>John Doe</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
            venenatis, turpis nec vulputate aliquet, massa ipsum fermentum
            augue, vitae consectetur dolor risus et velit. Vivamus commodo, nisi
            nec maximus blandit, libero urna faucibus nisi, a malesuada nisi dui
            vel tortor.
          </p>
          <p>
            Integer in nisi ut velit lacinia congue eu eget sem. Suspendisse
            pharetra nunc nec odio fermentum, in lacinia ipsum gravida. Vivamus
            vel magna id nulla posuere euismod in nec velit.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CEOPage;
